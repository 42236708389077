import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const Salary = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Salary Account</div>
          <div className='card-text fs-5'>Welcome to LoanpeLoan, your trusted financial partner. In this article, we will explore the benefits and features of a salary account, a specialized bank account designed specifically for employees to receive their salaries. We understand the importance of managing your hard-earned money efficiently, and a salary account can play a crucial role in achieving financial stability and convenience. Let's delve into the world of salary accounts and how they can empower you to take control of your finances.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>Features of Salary Account</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>No Minimum Balance Requirement: </b>
                  <span>One of the most significant advantages of a salary account is that it comes with no minimum balance requirement. This means that you don't have to maintain a minimum balance in your account to keep it operational.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>ATM/Debit Card: </b> <span>Most salary accounts come with a free ATM/debit card, which can be used to withdraw cash or make purchases.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Online Banking: </b> <span>Salary accounts come with online banking facilities, which allow you to access your account from anywhere and at any time. You can use online banking to check your account balance, view transaction history, transfer funds, and pay bills.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Cheque Book: </b> <span>Salary accounts also come with a cheque book facility. This can be useful if you need to make payments to someone who doesn't accept digital payments.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Interest Rates: </b> <span>Salary accounts come with higher interest rates than regular savings accounts. The interest rates offered by salary accounts vary from bank to bank.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Benefits of Salary Account</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Zero Balance Account: </b>
                  <span>As mentioned earlier, salary accounts come with no minimum balance requirement, which means that you can operate your account with zero balance.</span>
                </li>

                <li className='list-group-item border-0'>
                  <b>Higher Interest Rates: </b>
                  <span>Salary accounts offer higher interest rates than regular savings accounts, which can help you earn more on your savings.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Debit Card Benefits: </b>
                  <span>Salary accounts come with a free debit card, which can be used to avail discounts on various financial products.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Overdraft Facility: </b>
                  <span>Some banks offer overdraft facilities on salary accounts. This means that you can withdraw more money than what is available in your account.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Eligibility Criteria for Salary Account</div>

            <div className='fs-6'>To open a salary account, you need to be a salaried individual. The eligibility criteria for salary accounts may vary from bank to bank. Generally, you need to provide the following documents to open a salary account</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Identity Proof: </b>
                  <span>Aadhaar Card, PAN Card, Voter ID Card, or Passport.</span>
                </li>

                <li className='list-group-item border-0'>
                  <b>Address Proof : </b>
                  <span>Aadhaar Card, Voter ID Card, Passport, or Driving License.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Proof of Employment :</b>
                  <span>Offer letter, Appointment letter, or Salary Slip.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Account' interestIn='Salary Account'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Salary
