import { Button, Col, Container, Row } from 'react-bootstrap'
import { data } from '../../component/Common/data'
import ModalCom from '../../component/Common/Modal'
import Map from '../../component/GoogleMap'
import QueryFrom from '../../component/Common/QueryFrom'

const index = () => {
  return (
    <>
      <section className='contact-page-bg'>
        <Container fluid>
          <Row className='justify-content-center'>
            <Col sm={10}>
              <Row className='justify-content-center text-left'>
                <Col>
                  <div className='login-page'>
                    <h1 className='text-center mb-5'>Contact Us</h1>
                    <div className='mt-3 fs-5'>
                      <Map />
                      <p>
                        <span>email: </span>
                        <b>
                          <a className=' text-black' href={`mailto:${data.email}`}>
                            {data.email}
                          </a>
                        </b>
                      </p>
                      <p>
                        <span>Contact No.: </span>
                        <b>
                          <a className=' text-decoration-none text-black' href={`tel:${data.mobileNo}`}>
                            {data.mobileNo}
                          </a>
                        </b>
                      </p>
                      <p>
                        <span>Address: </span>
                        <b>{data.address}</b>
                      </p>
                      <p>Our customer support team is available 24/7 to assist you with any questions or issues you may have. We understand that your time is valuable and we want to ensure that your experience with LoanpeLoan is hassle-free.</p>
                      <p>Whether you have a question about our loan services, want to know more about our policies, or need assistance with a loan application, we are here to help. Our knowledgeable and friendly customer support team is ready to assist you with any questions you may have.</p>
                    </div>
                    <QueryFrom />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default index
