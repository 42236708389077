import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const Saving = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Saving Account</div>
          <div className='card-text fs-5'>Welcome to LoanpeLoan, your trusted financial partner. In this article, we will explore the importance of saving accounts as a cornerstone of personal finance. Saving accounts are essential financial tools that help individuals build a solid foundation for their future. At LoanpeLoan, we understand the significance of saving and the role it plays in achieving financial stability and realizing your goals. Let's delve into the world of saving accounts and discover how they can assist you in your financial journey.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>What is a Saving Account?</div>
            <div className='fs-6'>A saving account is a type of deposit account provided by banks and financial institutions that enables individuals to securely store and grow their money while earning a modest interest. It serves as a convenient and accessible platform for individuals to manage their funds, deposit surplus income, and accumulate savings over time. Saving accounts offer a range of features and benefits designed to support the financial goals and needs of individuals.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Key Features of Saving Accounts</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Interest Earnings:</b>
                  <span>One of the primary benefits of saving accounts is the ability to earn interest on the deposited funds. Banks pay interest on the average balance in the account, typically calculated on a daily or monthly basis. While the interest rates may vary, saving accounts generally provide a better return on investment compared to regular transactional accounts.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Safety and Security: </b> <span>Saving accounts are considered safe and secure, as they are regulated by banking authorities and come with deposit insurance coverage. This ensures that your funds are protected up to a certain limit, providing peace of mind and safeguarding your hard-earned money.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Liquidity:</b> <span>Saving accounts offer high liquidity, allowing you to withdraw funds as needed. Unlike other long-term investment options, such as fixed deposits or mutual funds, saving accounts provide immediate access to your funds without any penalties or waiting periods. This makes them suitable for managing regular expenses, emergencies, or short-term financial goals.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Convenience: </b> <span>Opening and maintaining a saving account is a simple and hassle-free process. Most banks offer online account opening facilities, enabling you to open an account from the comfort of your home. Additionally, saving accounts come with features like checkbooks, debit cards, and online banking, providing convenient access to your funds and allowing you to perform transactions with ease.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Savings Goal Tracking: </b> <span>Saving accounts serve as an effective tool for tracking and achieving your financial goals. By setting specific targets and regularly depositing funds into your account, you can monitor your progress and stay motivated to save. Some banks even offer goal-based saving accounts that provide additional features and rewards to help you achieve your objectives.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Benefits of Saving Accounts</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Financial Security: </b>
                  <span>Saving accounts provide a secure place to store your money, protecting it from theft or loss. Instead of keeping your savings at home, depositing them in a bank ensures they are safeguarded and easily accessible whenever you need them.</span>
                </li>

                <li className='list-group-item border-0'>
                  <b>Emergency Fund: </b>
                  <span>Saving accounts are ideal for building an emergency fund, which is crucial for unforeseen expenses or financial setbacks. Having a dedicated fund can provide a safety net during challenging times, allowing you to manage emergencies without resorting to debt or exhausting your investments.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Regular Saving Habit: </b>
                  <span>Opening a saving account encourages a regular saving habit. By setting aside a portion of your income consistently, you develop discipline and financial responsibility. Over time, this habit can significantly contribute to your financial well-being and help you achieve your long-term goals.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Future Planning: </b>
                  <span>Saving accounts are instrumental in planning for future expenses and goals. Whether you're saving for education, a down payment on a home, a dream vacation, or retirement, having a dedicated account allows you to accumulate funds gradually and stay focused on your objectives.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Interest Growth: </b>
                  <span>Saving accounts provide a platform for your money to grow through interest earnings. While the interest rates may not be as high as other investment options, the power of compounding over time</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Account' interestIn='Saving Account'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Saving
