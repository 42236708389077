import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const Current = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Current Account</div>
          <div className='card-text fs-5'>Welcome to LoanpeLoan, your reliable financial resource. In this article, we will explore the concept of a current account and its significance for businesses and individuals engaged in regular financial transactions. We understand the importance of efficient cash management for businesses and aim to provide you with valuable insights into current accounts and their benefits. Let's dive into the world of current accounts and how they can empower you to manage your finances effectively.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>Benefits of a Current Account</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Unlimited Transactions: </b>
                  <span>One of the main advantages of a current account is that there are no limits on the number of transactions you can make. This makes it easy to manage your business finances, as you can deposit and withdraw funds as often as you need to.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Cheque Facility: </b> <span>A current account provides the facility of issuing and receiving cheques, which is crucial for businesses. Cheques are still widely used in business transactions, and having a current account makes it easy to manage them.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Overdraft Facility: </b> <span>Current accounts also offer the facility of an overdraft, which allows businesses to borrow money when they need it. This can be helpful in managing cash flow or during unexpected expenses.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Online Banking: </b> <span>Most banks offer online banking facilities for current accounts, which makes it easy to manage your account from anywhere at any time. You can check your balance, view transaction history, and transfer funds online.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Merchant Services: </b> <span>Some banks also offer merchant services to businesses with current accounts. This allows businesses to accept payments through debit and credit cards, which can increase their revenue and improve customer experience.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Relationship Manager: </b> <span>Many banks assign a dedicated relationship manager to current account holders. This person can help with any account-related issues, provide advice on financial matters, and offer customized solutions to meet the unique needs of your business.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Documents Required for Opening a Current Account</div>
            <div className='fs-6'>To open a current account, you will need to provide the following documents</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Business Registration: </b>
                  <span>You will need to provide proof of business registration, such as a business registration certificate, partnership deed, or memorandum of association.</span>
                </li>

                <li className='list-group-item border-0'>
                  <b>PAN Card: </b>
                  <span>You will need to provide a copy of your business's PAN card.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Address Proof: </b>
                  <span>You will need to provide an address proof, such as a utility bill or a lease agreement.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Identity Proof: </b>
                  <span>You will need to provide an identity proof, such as a passport, voter ID, or driving license.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Business Plan: </b>
                  <span>Some banks may also require a business plan to understand your business and its operations.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Account' interestIn='Current Account'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Current
