import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import QueryFrom from './QueryFrom'

const ModalCom = (props) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <span className='modalBtn' onClick={handleShow}>
        {props.children}
      </span>

      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title>Apply Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QueryFrom {...props} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalCom
