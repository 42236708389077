import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import footerlogo from "../../assets/images/LPL_White.png";
import { Link } from "react-router-dom";
import { data } from "../Common/data";
const Footer = () => {
	return (
		<footer>
			<Container className="footer">
				<Row className="justify-content-center">
					<Col>
						<h4 className="mb-3 text-center">Trending Searches</h4>
						<ul className="search-keywords">
							<li>
								<Link to="/">Credit Score</Link>
							</li>
							<li>
								<Link to="/">Loan</Link>
							</li>
							<li>
								<Link to="/">Insurance</Link>
							</li>
							<li>
								<Link to="/">Investment</Link>
							</li>
							<li>
								<Link to="/">Calculator</Link>
							</li>
							<li>
								<Link to="/">Eligibility Calculator</Link>
							</li>
							<li>
								<Link to="/">Loan Against Property</Link>
							</li>
							<li>
								<Link to="/">Saving Schemes</Link>
							</li>
							<li>
								<Link to="/">Business Loan</Link>
							</li>
							<li>
								<Link to="/">Fixed Deposit</Link>
							</li>
						</ul>
					</Col>
				</Row>
				<Row className="justify-content-center my-4">
					<Col>
						<p>Redemption of awards does not reflect applicable taxes, if any. Any personal tax implications arising from this program are for the participants to assess and pay for in appropriate consultation with their personal tax advisors.</p>
						<p>Participation in this program does not create an employment or agency relationship with P&G; does not and is not intended to affect a participant’s current employment status; and does not entitle the participant to any compensation or benefit plan or qualify a participant for any right, program, or policy from P&G or its subsidiaries.</p>
						<p>
							Ethics Compliance – This program scope concerns qualifying U.S. sales to private companies of certain P&G products outlined in the listed promotions (which are limited to P&G Fabric, Home and Family Care products). Sales outside of this scope will not qualify. For the avoidance of doubt, P&G will not be providing (or permitting program participants or anyone else to provide on its behalf) any incentives i) to government employees (including without limitation school or university employees, buyers in military channels, hospital administrators, employees of skilled nursing/assisted living facilities, public procurement managers, or any other federal, state, or local entity or employee); ii) to any health care professionals or institutions (including without limitation doctors, hospitals, clinics, dentists, nurses, hygienists, or any other patient care setting, whether public or private in their practice); iii) in connection with sales of P&G health care or
							oral care products; or iv) in connection with sales to organizations that seek or receive state or federal health care program reimbursement for P&G products. Program participants agree that they will not claim or offer incentives for sales in violation of this program scope.
						</p>
					</Col>
				</Row>

				<Row className="mt-5">
					<Col xs={6} sm={3}>
						<h4>Services</h4>
						<ul className="footer-links">
							<li>
								<Link to="/">Loan</Link>
							</li>
							<li>
								<Link to="/">Insurance</Link>
							</li>
							<li>
								<Link to="/">Investment</Link>
							</li>
							<li>
								<Link to="/">Saving Schemes</Link>
							</li>
							<li>
								<Link to="/">Fixed Deposit</Link>
							</li>
						</ul>
					</Col>
					<Col xs={6} sm={3}>
						<h4>Tools</h4>
						<ul className="footer-links">
							<li>
								<Link to="/">Credit Score</Link>
							</li>
							<li>
								<Link to="/">Calculator</Link>
							</li>
							<li>
								<Link to="/">Eligibility Calculator</Link>
							</li>
						</ul>
					</Col>
					<Col xs={6} sm={3}>
						<h4>Company</h4>
						<ul className="footer-links">
							<li>
								<Link to="/">About Us</Link>
							</li>
							<li>
								<Link to="/">Services</Link>
							</li>
							<li>
								<Link to="/">Careers</Link>
							</li>
							<li>
								<Link to="/">Terms & Conditions</Link>
							</li>
							<li>
								<Link to="/">Privacy Policy</Link>
							</li>
							<li>
								<Link to="/">Support</Link>
							</li>
						</ul>
					</Col>
					<Col xs={6} sm={3} className="footer_contact">
						<h4>Contact Us</h4>
						<img src={footerlogo} className="w-50" alt="Loan pe loan white logo" />
						<p>
							<b>Address: </b>
							{data.address}
						</p>
						<p>
							<b>email: </b>
							<span>
								<a className=" text-decoration-none text-white" href={`mailto:${data.email}`}>
									{data.email}
								</a>
							</span>
						</p>
						<p>
							<b>Contact No.: </b>
							<span>
								<a className=" text-decoration-none text-white" href={`tel:${data.mobileNo}`}>
									{data.mobileNo}
								</a>
							</span>
						</p>
					</Col>
				</Row>
			</Container>
			<div className="copyright">
				<Container>
					<p>
						©Copyright {new Date().getFullYear()}{" "}
						<a href="https://newitzone.com/" target="new" title="NewItZone">
							NewItZone
						</a>
						. All Rights Reserved.
					</p>
				</Container>
			</div>
		</footer>
	);
};
export default Footer;
