import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/theme.scss';
import routes from './routing/Routing';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <Router>{routes}</Router>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover theme="light" />
    </>
  );
}

export default App;
