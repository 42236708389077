import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const CSLoan = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>CS Loan</div>
          <div className='card-text fs-5'>As a CS, you are an expert in corporate law, governance, and compliance. But managing your own finances can be a challenge, especially if you want to invest in your practice or meet unexpected expenses. That's where LoanPeLoan comes in. Our CS Loans are designed to provide you with the financial support you need to grow your practice, invest in new technology, or expand your office. With flexible repayment options and competitive interest rates, our CS Loans are the perfect solution for your financial needs.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>Features of CA and CS Loans:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>LoanPeLoan offers CA Loans and CS Loans with loan amounts up to Rs. 50 lakhs.</li>
                <li className='list-group-item border-0'>Our loans come with flexible repayment options ranging from 12 to 60 months.</li>
                <li className='list-group-item border-0'>We offer competitive interest rates on our loans, ensuring that you get the best deal possible.</li>
                <li className='list-group-item border-0'>Our loan application process is quick and hassle-free, allowing you to get the funds you need in no time.</li>
                <li className='list-group-item border-0'>We offer doorstep service, so you don't have to worry about visiting our offices to apply for a loan.</li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Eligibility Criteria:</div>
            <div className='fs-6'>To be eligible for a CA or CS Loan from LoanPeLoan, you must meet the following criteria:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>You must be a practicing CA or CS with a minimum of 4 years of experience.</li>
                <li className='list-group-item border-0'>You must have a minimum income of Rs. 5 lakhs per annum.</li>
                <li className='list-group-item border-0'>You must have a good credit score of 750 or above.</li>
                <li className='list-group-item border-0'>You must have a stable income and employment history.</li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Documents Required for CS Loan:</div>
            <div className='fs-6'>To apply for a CS Loan at LoanpeLoan, you need to submit the following documents:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>KYC Documents:</b> Aadhaar card, PAN card, passport, or driving license.
                </li>
                <li className='list-group-item border-0'>
                  <b>Proof of Income:</b> Salary slips, bank statements, or income tax returns.
                </li>
                <li className='list-group-item border-0'>
                  <b>Proof of Education:</b> Degree certificate or mark sheets.
                </li>
                <li className='list-group-item border-0'>
                  <b>Proof of Practice:</b> Registration certificate, practice certificate, or establishment certificate.{' '}
                </li>
                <li className='list-group-item border-0'>
                  <b>Other Documents:</b> Property papers (if applicable), business plan (if applicable)
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Loan' interestIn='CS Loan'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CSLoan
