import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const EducationLoan = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Education Loan</div>
          <div className='card-text fs-5'>At LoanpeLoan, we believe that education is the key to unlocking your potential and achieving your career goals. Our education loan products from reputable lenders ensure that you can find the financing solution that meets your unique needs</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>Fund Your Dreams with Education Loans from LoanpeLoan</div>
            <div className='fs-6'>Investing in your education is one of the best ways to achieve your career goals and fulfill your potential. At LoanpeLoan, we understand the value of education and offer a range of education loan products from trusted lenders to help you fund your dreams.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>What is an Education Loan?</div>
            <div className='fs-6'>An education loan is a type of loan designed to help students and their families finance the cost of education-related expenses such as tuition fees, accommodation, textbooks, and other related expenses. Education loans can be availed for undergraduate, postgraduate, and professional courses, both in India and abroad.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Why Choose LoanpeLoan for Your Education Loan?</div>
            <div className='fs-6'>At LoanpeLoan, we work with a network of trusted lenders who offer education loan products with competitive interest rates, flexible repayment terms, and quick funding. Our user-friendly platform simplifies the loan application process, making it easy for you to find the right education loan for your needs.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Benefits of Education Loans</div>
            <div className='fs-6'>One of the primary benefits of education loans is that they provide you with the necessary financial support to pursue your education and achieve your career goals. Education loans typically offer competitive interest rates, making them an affordable financing option for students and their families. Additionally, education loans offer flexible repayment terms, allowing you to choose a repayment schedule that suits your financial situation.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Eligibility and Application Process</div>
            <div className='fs-6'>
              To be eligible for an education loan, lenders typically require that you or your co-signer have a good credit score and steady income. Additionally, lenders may require that you provide proof of admission to an accredited educational institution and a detailed breakdown of your education-related expenses.
              <br />
              The loan application process on LoanpeLoan is straightforward and user-friendly. Our platform guides you through the application process, prompting you to provide essential details about your financial situation and education loan needs. Once you submit your application, our system matches you with potential lenders who can meet your requirements.
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Applying for an Education Loan on LoanpeLoan</div>
            <div className='fs-6'>Applying for an education loan on LoanpeLoan is easy and hassle-free. Our platform matches you with lenders who offer the education loan products you need, allowing you to compare loan options and choose the one that best suits your financial situation. Once you choose a lender, you'll receive funding quickly, allowing you to invest in your education with confidence.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Types of Education Loans</div>
            <div className='fs-6'>LoanpeLoan offers various types of education loans to meet different needs, including:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b> Undergraduate Education Loan : </b>
                  <span>This type of loan is available for students pursuing undergraduate courses in India and abroad.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Postgraduate Education Loan:</b> <span>This type of loan is available for students pursuing postgraduate courses in India and abroad.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Study Abroad Loan:</b> <span> This type of loan is available for students who wish to pursue their education abroad.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Vocational Education Loan:</b> <span> This type of loan is available for students pursuing vocational courses such as diploma or certificate courses.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Loan Amount and Repayment</div>
            <div className='fs-6'>The loan amount for education loans varies depending on the course, institution, and location. Generally, lenders offer loans ranging from Rs. 1 lakh to Rs. 1.5 crore for education loans. Repayment terms are also flexible, with most lenders offering a repayment tenure of up to 15 years.</div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Loan' interestIn='Education Loan'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EducationLoan
