import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const Health = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Health Insurance</div>
          <div className='card-text fs-5'>Health is wealth, and that is why health insurance is essential. With rising healthcare costs, it is essential to have health insurance to protect you and your loved ones in times of medical emergencies. A health insurance policy provides financial assistance in case of medical emergencies, hospitalization, and other health-related expenses. At LoanpeLoan, we offer various health insurance plans that cater to your specific needs and budget.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>Understanding Health Insurance</div>
            <div className='fs-6'>Health insurance is a type of insurance that covers the medical expenses of an individual or a group of people. The policyholder pays a premium, and in return, the insurance company provides financial assistance in case of medical emergencies, hospitalization, and other health-related expenses. Health insurance policies can be purchased for an individual, family, or a group of people.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Types of Health Insurance Policies</div>
            <div className='fs-6'>There are different types of health insurance policies, and choosing the right policy can be overwhelming. At LoanpeLoan, we offer the following types of health insurance policies:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Individual Health Insurance:</b>
                  <span>Individual health insurance policies cover the medical expenses of an individual. These policies are ideal for people who do not have a family or dependents.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Family Health Insurance: </b> <span>Family health insurance policies cover the medical expenses of the entire family. These policies are ideal for families with dependents.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Group Health Insurance:</b> <span>Group health insurance policies cover the medical expenses of a group of people, such as employees of a company or members of an organization.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Senior Citizen Health Insurance:</b> <span>Senior citizen health insurance policies are designed for people who are above the age of 60 years. These policies cover medical expenses related to old age ailments.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Benefits of Health Insurance:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Financial Protection:</b>
                  <span>Health insurance provides financial assistance in case of medical emergencies, hospitalization, and other health-related expenses. It helps you avoid the burden of paying for expensive medical treatments out of your pocket.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Peace of Mind:</b>
                  <span>Having health insurance offers peace of mind as you know that you are financially protected in case of medical emergencies.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Tax Benefits:</b> <span>The premium paid for health insurance is eligible for tax deductions under Section 80D of the Income Tax Act. </span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Cashless Hospitalization:</b>
                  <span>Most health insurance policies offer cashless hospitalization, which means that you can avail medical treatment without paying anything upfront. The insurance company settles the bill directly with the hospital.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Health Check-ups:</b>
                  <span>Many health insurance policies offer free health check-ups, which can help you detect health problems at an early stage.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Insurance' interestIn='Health Insurance'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Health
