import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const PublicProvidentFund = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Public Provident Fund(PPF)</div>
          <div className='card-text fs-5'>Welcome to LoanPeLoan, your trusted financial partner. In this article, we will explore the Public Provident Fund (PPF), a popular investment option in India known for its safety, tax benefits, and long-term wealth creation potential. We understand the importance of financial planning and aim to provide you with valuable insights into the PPF and how it can help you achieve your savings and investment goals. Let's dive into the world of the Public Provident Fund and its benefits.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>What is Public Provident Fund (PPF)?</div>
            <div className='fs-6'>Public Provident Fund (PPF) is a long-term savings scheme that is backed by the Government of India. It is a safe and secure investment option for individuals who want to invest for a longer period of time and generate fixed returns. The scheme is regulated by the Ministry of Finance and is offered by designated banks and post offices across the country.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Features of Public Provident Fund</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Tenure: </b>
                  <span>The PPF scheme has a tenure of 15 years. The account can be extended for an additional period of 5 years after maturity.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Investment: </b> <span>The minimum investment amount is Rs. 500 and the maximum investment amount is Rs. 1.5 lakh in a financial year.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Interest rate: </b> <span>: The interest rate is fixed by the government on a quarterly basis. The current interest rate for the January-March quarter is 7.1% per annum.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Tax benefits: </b> <span>PPF offers tax benefits under Section 80C of the Income Tax Act, 1961. The investments, interest earned, and maturity proceeds are tax-free.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Withdrawal: </b> <span>Partial withdrawals are allowed after the completion of the 5th financial year. The maximum amount that can be withdrawn is 50% of the balance at the end of the 4th financial year or the immediately preceding year, whichever is lower.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Loan facility: </b> <span>PPF account holders can avail of a loan facility against their PPF account from the 3rd financial year up to the 6th financial year.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Nomination facility: </b> <span>The PPF scheme provides a nomination facility, where the account holder can nominate one or more persons to receive the proceeds of the account in the event of their death.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Benefits of Public Provident Fund</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Risk-free: </b>
                  <span>PPF is a government-backed investment option, which makes it a risk-free investment. The investment amount is guaranteed and the returns are fixed.</span>
                </li>

                <li className='list-group-item border-0'>
                  <b>Tax-free: </b>
                  <span>PPF offers tax benefits under Section 80C of the Income Tax Act, 1961. The investments, interest earned, and maturity proceeds are tax-free.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Long-term investment: </b>
                  <span>PPF is a long-term investment option with a tenure of 15 years. This makes it a good investment option for those who want to plan for their long-term financial goals.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>High returns: </b>
                  <span>PPF offers a fixed rate of return, which is currently at 7.1% per annum. The interest rate is higher than most other fixed-income investments.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Loan facility: </b>
                  <span>PPF account holders can avail of a loan facility against their PPF account from the 3rd financial year up to the 6th financial year.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Withdrawal facility: </b>
                  <span> Partial withdrawals are allowed after the completion of the 5th financial year. This makes it a good investment option for those who may require funds in the future.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Eligibility criteria for Public Provident Fund:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>Any individual, whether resident or non-resident, can open a PPF account.</li>
                <li className='list-group-item border-0'>A PPF account can be opened on behalf of a minor by their parents or legal guardians.</li>
                <li className='list-group-item border-0'>An individual can only have one PPF account</li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Investment' interestIn='Public Provident Fund'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicProvidentFund
