import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const Life = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Life Insurance</div>
          <div className='card-text fs-5'>At LoanpeLoan, we understand that purchasing life insurance can be overwhelming. With so many options and confusing jargon, it's easy to get lost in the sea of information. That's why we're here to help you navigate through the process and make an informed decision that's best for you and your loved ones.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>What is Life Insurance?</div>
            <div className='fs-6'>In simple terms, life insurance is a contract between an individual and an insurance company. The individual (also known as the policyholder) pays regular premiums to the insurance company, and in return, the company agrees to pay a lump sum (also known as the death benefit) to the policyholder's beneficiaries upon their death.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Types of Life Insurance Policies</div>
            <div className='fs-6'>There are different types of life insurance policies, and it's important to understand each one before making a decision. Here are the three main types of life insurance policies:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Term Life Insurance: </b>
                  <span>This type of policy provides coverage for a specific period, usually between 10 to 30 years. If the policyholder passes away during the term, the beneficiaries receive the death benefit. If the policyholder outlives the term, the coverage ends, and no benefits are paid.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Whole Life Insurance: </b> <span>This type of policy provides coverage for the policyholder's entire life as long as premiums are paid. It also builds cash value over time, which the policyholder can borrow against or withdraw. However, whole life insurance is generally more expensive than term life insurance.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Universal Life Insurance: </b> <span>This type of policy is similar to whole life insurance, but it offers more flexibility in terms of premiums and death benefits. It also allows the policyholder to invest the cash value in the stock market, potentially earning higher returns.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Why is Life Insurance Important?</div>
            <div className='fs-6'>Life insurance is important because it provides financial security for your loved ones in case of an unexpected tragedy. The death benefit can be used to cover funeral expenses, pay off outstanding debts and mortgages, and provide a source of income for your family. It can also be used to fund your children's education or support your spouse's retirement.</div>
            <div className='fs-6'>At LoanpeLoan, we believe that life insurance is an essential component of financial planning. We understand that every individual has different needs and priorities, and that's why we offer a variety of life insurance policies to suit your specific requirements.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Our Process</div>
            <div className='fs-6'>At LoanpeLoan, we make purchasing life insurance a hassle-free experience. Our team of experts will guide you through the entire process, from selecting the right policy to completing the application. Here's a brief overview of our process:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Needs Assessment: </b>
                  <span>We begin by assessing your needs and priorities to determine the appropriate coverage amount and policy type.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Policy Selection: </b>
                  <span>We provide you with a variety of life insurance policies to choose from, based on your needs and budget.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Application: </b> <span>Once you've selected a policy, we help you complete the application and provide any necessary documentation.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Underwriting: </b>
                  <span>The insurance company will review your application and determine your premium based on factors such as age, health, and lifestyle.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Approval and Payment: </b>
                  <span>Once the underwriting process is complete, the insurance company will approve your policy, and you can begin paying your premiums.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Insurance' interestIn='Life Insurance'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Life
