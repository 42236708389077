import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const FixedDeposit = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Fixed Deposit</div>
          <div className='card-text fs-5'>Welcome to LoanpeLoan, your reliable source for financial information. In this article, we will explore fixed deposits (FDs), a popular investment option for individuals looking for a secure and stable way to grow their savings. We understand the importance of making informed financial decisions, and fixed deposits offer a range of benefits that make them an attractive investment avenue. Let's dive into the world of fixed deposits and discover how they can help you achieve your financial goals.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>What is a Fixed Deposit?</div>
            <div className='fs-6'>A fixed deposit is an investment instrument that allows you to deposit a sum of money with a bank or financial institution for a specific period of time, ranging from a few months to a few years. The deposited amount earns interest at a fixed rate, which is determined by the bank or financial institution. The interest rate for fixed deposits varies depending on the tenure of the deposit and the amount invested.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>How do Fixed Deposits work?</div>
            <div className='fs-6'>
              When you invest in a fixed deposit, you agree to leave your money with the bank or financial institution for a specific period of time. During this period, the bank or financial institution uses your money to fund its lending operations. In return for the use of your money, the bank or financial institution pays you a fixed interest rate.
              <br />
              At the end of the tenure, you receive the principal amount along with the interest earned on it. The interest can either be paid out at maturity or at regular intervals, depending on the terms and conditions of the fixed deposit.
            </div>
            <div className='fs-6'>Benefits of Fixed Deposits:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Guaranteed returns: </b>
                  <span>One of the biggest advantages of investing in fixed deposits is that they provide guaranteed returns. The interest rate offered by banks or financial institutions is fixed for the entire tenure of the deposit, which means you can be assured of the returns you will earn.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Low-risk investment: </b> <span>Fixed deposits are considered a low-risk investment as they are not affected by market fluctuations. The returns are guaranteed, and your investment is safe with the bank or financial institution.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Flexible tenure options: </b> <span>Fixed deposits offer flexible tenure options ranging from a few months to a few years. You can choose a tenure that suits your investment goals and financial needs.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Easy to invest: </b> <span>Investing in fixed deposits is easy and convenient. You can open a fixed deposit account with a bank or financial institution either online or by visiting their branch.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>High liquidity: </b> <span> Fixed deposits offer high liquidity as you can break your deposit before maturity in case of any emergency. However, there may be penalties for premature withdrawal.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Things to consider before investing in Fixed Deposits:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Interest rate: </b>
                  <span>Before investing in fixed deposits, it is important to compare the interest rates offered by different banks or financial institutions. Look for banks that offer higher interest rates to maximize your returns.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Tenure: </b>
                  <span>Choose a tenure that matches your investment goals and financial needs. Keep in mind that longer tenures typically offer higher interest rates.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Penalties for premature withdrawal: </b> <span>Check the penalties for premature withdrawal before investing in fixed deposits. In case of an emergency, you may need to withdraw your deposit before maturity, and penalties for premature withdrawal can eat into your returns.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Tax implications: </b>
                  <span>The interest earned on fixed deposits is taxable. Before investing in fixed deposits, consider the tax implications and plan your investments accordingly.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Investment' interestIn='Fixed Deposit'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FixedDeposit
