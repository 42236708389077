import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const DebitCard = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Debit cards</div>
          <div className='card-text fs-5'>Debit cards have become an essential financial tool for many people around the world. They offer a convenient and secure way to access your money and make purchases without the need for cash or checks. At LoanpeLoan, we understand the importance of debit cards in today's world, and we are committed to helping our customers find the best debit card options available. In this article, we will discuss what debit cards are, how they work, and their benefits.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>What is a Debit Card?</div>
            <div className='fs-6'>A debit card is a plastic payment card that allows you to access your bank account funds electronically. When you use your debit card, the funds are transferred directly from your bank account to the merchant's account. Unlike credit cards, which allow you to borrow money, debit cards only allow you to spend what you already have in your account.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>How Do Debit Cards Work?</div>
            <div className='fs-6'>Debit cards work by accessing the funds in your bank account. When you make a purchase using your debit card, the merchant will swipe or insert the card into their payment terminal, and you will be prompted to enter your personal identification number (PIN) or sign a receipt. The payment terminal will then send a request to your bank to verify that you have sufficient funds to cover the purchase. If you do, the funds will be transferred from your account to the merchant's account. If you don't have sufficient funds, the transaction will be declined.</div>
          </div>

          <div className='my-4'>
            <div className='h3'>Types of Debit Cards:</div>
            <div className='fs-6'>There are two types of debit cards – online debit cards and offline debit cards. Online debit cards are linked to your bank account and require you to enter your PIN to complete a transaction. Offline debit cards, on the other hand, are processed like credit cards and require you to sign for the transaction.</div>
            <div className='fs-6'>Benefits of Using Debit Cards:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Convenience: </b>
                  <span>Debit cards allow you to make purchases without the need for cash or checks, making them a convenient payment option.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Security: </b> <span>Debit cards are safer than carrying cash since they can be easily cancelled if lost or stolen.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Budgeting: </b> <span>Debit cards help you stick to your budget since you can only spend what you have in your account.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Rewards: </b> <span>Some debit cards offer rewards programs that allow you to earn points or cash back on your purchases.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Access to ATMs: </b> <span>Debit cards give you access to ATMs, allowing you to withdraw cash or check your account balance.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Card' interestIn='Debit Card'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DebitCard
