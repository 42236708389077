import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const DoctorLoan = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Doctor Loan</div>
          <div className='card-text fs-5'>As a medical professional, you are dedicated to providing the best possible care to your patients. However, the cost of setting up and running a medical practice can be high, making it difficult for doctors to achieve their dreams. This is where Doctor Loans can come in handy. At LoanpeLoan, we understand the unique financial needs of doctors and offer tailored Doctor Loans to help them achieve their goals.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>What is a Doctor Loan?</div>
            <div className='fs-6'>A Doctor Loan is a type of loan designed specifically for medical professionals, including doctors, dentists, and veterinarians. These loans can be used for various purposes, such as setting up a new practice, expanding an existing one, purchasing equipment, or financing other professional needs. Doctor Loans typically offer competitive interest rates and flexible repayment options, making them an attractive option for medical professionals.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Features of Doctor Loans at LoanpeLoan:</div>
            <div className='fs-6'>At LoanpeLoan, we offer Doctor Loans with a range of features and benefits tailored to meet the specific needs of medical professionals. Here are some of the key features of our Doctor Loans:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>High Loan Amounts: </b>
                  <span>We understand that setting up or expanding a medical practice can be expensive. That's why we offer high loan amounts of up to Rs. 2 Crore to help medical professionals achieve their goals.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Competitive Interest Rates: </b> <span> We offer Doctor Loans at competitive interest rates, starting from as low as 8.50% p.a. This ensures that our customers can access affordable financing without worrying about high interest rates.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Flexible Repayment Options: </b> <span>We offer flexible repayment options to help medical professionals manage their finances better. Borrowers can choose from a range of repayment options, including EMIs, bullet payments, and customized repayment schedules.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Quick Disbursal: </b> <span>We understand that medical professionals often require quick access to funds. That's why we offer quick disbursal of Doctor Loans, with funds disbursed within 48 hours of loan approval.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Minimal Documentation: </b> <span>We know that medical professionals are busy people, which is why we have streamlined our loan application process to require minimal documentation. This ensures a hassle-free and quick loan application process.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Eligibility Criteria for Doctor Loans:</div>
            <div className='fs-6'>To be eligible for a Doctor Loan at LoanpeLoan, you need to meet the following criteria:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>You must be a medical professional, including a doctor, dentist, or veterinarian.</li>
                <li className='list-group-item border-0'>You must be an Indian citizen or resident.</li>
                <li className='list-group-item border-0'>You must be between 25 and 65 years of age.</li>
                <li className='list-group-item border-0'>You must have a minimum work experience of 3 years. </li>
                <li className='list-group-item border-0'>You must have a minimum annual income of Rs. 5 Lakhs. </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Documents Required for Doctor Loans:</div>
            <div className='fs-6'>To apply for a Doctor Loan at LoanpeLoan, you need to submit the following documents:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>KYC Documents:</b> Aadhaar card, PAN card, passport, or driving license.
                </li>
                <li className='list-group-item border-0'>
                  <b>Proof of Income:</b> Salary slips, bank statements, or income tax returns.
                </li>
                <li className='list-group-item border-0'>
                  <b>Proof of Education:</b> Degree certificate or mark sheets.
                </li>
                <li className='list-group-item border-0'>
                  <b>Proof of Practice:</b> Registration certificate, practice certificate, or establishment certificate.{' '}
                </li>
                <li className='list-group-item border-0'>
                  <b>Other Documents:</b> Property papers (if applicable), business plan (if applicable)
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Loan' interestIn='Doctor Loan'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoctorLoan
