import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const EmployeeProvidentFund = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Employees Provident Fund (EPF)</div>
          <div className='card-text fs-5'>Welcome to LoanpeLoan, your trusted financial resource. In this article, we will explore the Employees' Provident Fund (EPF), a social security scheme in India that aims to provide financial security and retirement benefits to employees. We understand the importance of understanding your rights and benefits as an employee, and the EPF plays a crucial role in ensuring your long-term financial well-being. Let's delve into the world of the Employees' Provident Fund and its benefits.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>EPF Scheme</div>
            <div className='fs-6'>The EPF scheme is a retirement savings scheme for employees working in the organized sector. Under this scheme, a certain percentage of the employee's salary is deducted each month and deposited in the employee's EPF account. The employer also contributes an equal amount to the employee's EPF account. The employee can withdraw the amount in their EPF account after their retirement or resignation from the job. The EPF scheme is applicable to all establishments that employ 20 or more people.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Benefits of EPF Plan</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Retirement Benefits: </b>
                  <span>The primary benefit of the EPF scheme is retirement benefits. The EPF scheme ensures that the employee has a source of income after their retirement. The employee can withdraw the amount in their EPF account after their retirement or pensionable age. The amount withdrawn is tax-free.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Pension Benefits: </b> <span>The EPF scheme also provides pension benefits to the employee. The employee can opt for the pension scheme by contributing to the EPS (Employees' Pension Scheme) account. The pension amount is calculated based on the employee's contribution and years of service.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Social Security Benefits: </b> <span>The EPF scheme also provides social security benefits to the employee. The scheme provides financial support to the employee in case of a medical emergency, disablement, or death.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Tax Benefits: </b> <span>The employee's contribution to the EPF scheme is tax-deductible under Section 80C of the Income Tax Act. The interest earned on the EPF account is also tax-free.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Nomination Facility: </b> <span>The EPF scheme also provides a nomination facility to the employee. The employee can nominate their family members as the nominee for their EPF account. In case of the employee's death, the nominee will receive the EPF amount.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>EPF Withdrawal Rules</div>
            <div className='fs-6'>The EPF amount can be withdrawn by the employee after their retirement, resignation, or termination from the job. The EPF amount can also be withdrawn for the following purposes:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>Medical Treatment</li>
                <li className='list-group-item border-0'>Home Loan Repayment</li>
                <li className='list-group-item border-0'>Marriage</li>
                <li className='list-group-item border-0'>Education</li>
                <li className='list-group-item border-0'>Purchase of Land or House</li>
                <li className='list-group-item border-0'>Renovation of House </li>
              </ol>
            </div>
            <div className='fs-6'>The EPF amount can be withdrawn partially or fully depending on the purpose of withdrawal. The employee can withdraw up to 90% of the EPF amount for the purchase or construction of a house.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>EPF Interest Rates</div>
            <div className='fs-6'>The EPF interest rates are decided by the Ministry of Labour and Employment, Government of India. The interest rates are revised every year. The EPF interest rates for the financial year 2021-22 are 8.5% per annum.</div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Investment' interestIn='Employees Provident Fund'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeProvidentFund
