import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import * as Yup from 'yup'
import { data } from './data'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RotatingLines } from 'react-loader-spinner'

const QueryFrom = (props) => {
  const [interestOpt, setInterestOpt] = useState([])
  const [itemIdx, setItemIdx] = useState('')

  const [loader, setLoader] = useState(false)
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    mobile: '',
    serviceType: '',
    interestIn: '',
    description: '',
  })

  useEffect(() => {
    const filterInterOption = data.Interest.filter((val) => val.serviceType === props.serviceType)
    if (props.serviceType) {
      setInitialValues((prev) => ({ ...prev, serviceType: props.serviceType }))
      setInterestOpt(filterInterOption)
    }
    if (props.interestIn) {
      setInitialValues((prev) => ({ ...prev, interestIn: props.interestIn }))
      setItemIdx(filterInterOption.filter((val) => val.label === props.interestIn)[0])
    }
  }, [props])

  const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/
  const validationSchema = Yup.object({
    name: Yup.string().min(2).max(100).required('Enter your Name'),
    email: Yup.string().email(),
    mobile: Yup.string().matches(phoneRegExp, 'Mobile number is not valid').required('Enter your Mobile no.'),
    serviceType: Yup.string().required('Select Service type'),
    interestIn: Yup.string().required('Select your interest'),
  })

  const handleSubmit = async (values, actions) => {
    try {
      const { name, email, mobile, interestIn, description } = values
      setLoader(true)
      const { data } = await axios.post('https://newitzone.com/api/query', {
        name: name,
        email: email,
        mobileNo: mobile,
        queryType: interestIn,
        description: description,
        isSiteChange: 2,
      })
      data.error ? toast.error(data.error) : toast.success(data.message)
      actions.resetForm()
      setLoader(false)
    } catch (error) {
      toast.error(error.message)
      setLoader(false)
    }
  }
  const onChangeSelect = (e, name, setValues) => {
    setValues((prev) => ({ ...prev, [name]: e.value }))
    if (name === 'serviceType') {
      setInterestOpt(data.Interest.filter((val) => val.serviceType === e.value))
    }
  }

  return (
    <>
      {loader && (
        <div className='loader'>
          <RotatingLines strokeColor='grey' strokeWidth='5' animationDuration='0.75' width='96' visible={true} />
        </div>
      )}

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={handleSubmit}>
                {({ errors, touched, setValues }) => (
                  <Form>
                    <Row>
                      <Col md={6} className='mt-3'>
                        <div className='form-group'>
                          <label htmlFor='name'>
                            Name <span className=' text-danger'>*</span>
                          </label>
                          <Field type='text' name='name' id='name' placeholder='Enter your Name' maxLength={100} className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />

                          <ErrorMessage name='name' component='div' className='invalid-feedback' />
                        </div>
                      </Col>
                      <Col md={6} className='mt-3'>
                        <div className='form-group'>
                          <label htmlFor='email'>Email</label>
                          <Field type='email' name='email' id='email' placeholder='Enter your Eamil' className='form-control' maxLength={100} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className='mt-3'>
                        <div className='form-group'>
                          <label htmlFor='mobile'>
                            Mobile No.<span className=' text-danger'>*</span>
                          </label>
                          <Field
                            type='mobile'
                            name='mobile'
                            id='mobile'
                            placeholder='Enter your Mobile'
                            maxLength={25}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            className={'form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')}
                          />

                          <ErrorMessage name='mobile' component='div' className='invalid-feedback' />
                        </div>
                      </Col>

                      {props.serviceType === undefined ? (
                        <Col md={6} className='mt-3'>
                          <div className='form-group'>
                            <label htmlFor='serviceType'>
                              Service Type<span className=' text-danger'> *</span>
                            </label>
                            <Select name='serviceType' id='serviceType' onChange={(e) => onChangeSelect(e, 'serviceType', setValues)} options={data.ServiceType} className={errors.serviceType && touched.serviceType ? 'form-control is-invalid' : ''} />
                            <ErrorMessage name='serviceType' component='div' className='invalid-feedback' />
                          </div>
                        </Col>
                      ) : (
                        ''
                      )}
                      <Col md={6} className='mt-3'>
                        <div className='form-group'>
                          <label htmlFor='interestIn'>
                            Interest In<span className=' text-danger'> *</span>
                          </label>
                          {itemIdx && <Select name='interestIn' id='interestIn' defaultValue={itemIdx} options={interestOpt} isDisabled />}
                          {!itemIdx && <Select name='interestIn' id='interestIn' onChange={(e) => onChangeSelect(e, 'interestIn', setValues)} defaultValue={interestOpt[itemIdx]} options={interestOpt} isDisabled={props.interestIn ? true : false} className={errors.interestIn && touched.interestIn ? 'form-control is-invalid' : ''} />}
                          <ErrorMessage name='interestIn' component='div' className='invalid-feedback' />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='mt-3'>
                        <div className='form-group'>
                          <label htmlFor='description'>Description</label>
                          <Field as='textarea' name='description' id='description' placeholder='Description' rows='5' maxLength={300} className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />

                          <ErrorMessage name='description' component='div' className='invalid-feedback' />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='mt-3 d-flex justify-content-center'>
                          <Button type='submit'>Apply</Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default QueryFrom
