import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const RecurringDeposit = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Recurring Deposit</div>
          <div className='card-text fs-5'>
            A recurring deposit is a type of investment account that allows individuals to deposit a fixed amount of money at regular intervals, typically monthly, for a predetermined period of time. At the end of the deposit term, the account holder receives a lump sum amount, which includes the principal amount as well as interest earned on the deposits made. It is an attractive option for people who want to save a fixed amount of money every month and earn a steady return on their investment.
            <br />
            At LoanpeLoan, we offer recurring deposit accounts with attractive interest rates and flexible deposit terms to suit the financial goals of our customers.
          </div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>Benefits of Recurring Deposits:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Fixed Savings: </b>
                  <span>Recurring deposits allow you to save a fixed amount of money every month, making it an ideal option for people who want to cultivate a saving habit.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Attractive Interest Rates: </b> <span>Recurring deposits offer higher interest rates compared to regular savings accounts, which means that your money earns a better return on investment.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Flexible Tenure: </b> <span>LoanpeLoan, we offer flexible deposit tenures ranging from 6 months to 10 years, allowing you to choose a tenure that suits your financial goals.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Easy to Start: </b> <span>Opening a recurring deposit account with LoanpeLoan is a simple and hassle-free process. You can start investing in a recurring deposit account with just a few clicks on our website.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>No Risk: </b> <span> Unlike investments in stocks or mutual funds, recurring deposits are low-risk investments that offer guaranteed returns, making them a suitable option for risk-averse investors.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Why choose LoanpeLoan for Recurring Deposits?</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Attractive Interest Rates: </b>
                  <span>We offer competitive interest rates on recurring deposits, ensuring that your money earns the best possible return on investment.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Flexibility: </b> <span>We offer flexible deposit tenures ranging from 6 months to 10 years, allowing you to choose a tenure that suits your financial goals.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Hassle-free Account Opening: </b> <span>Our online account opening process is simple and easy, allowing you to open a recurring deposit account from the comfort of your home.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Safe and Secure: </b> <span>At LoanpeLoan, we use the latest security measures to ensure the safety of your deposits and personal information.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Expert Advice: </b> <span>Our team of financial experts is always available to provide you with expert advice on your investment decisions.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Investment' interestIn='Recurring Deposit'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecurringDeposit
