import React, { useState } from 'react'
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap'
import promoimg from '../../assets/images/promo-img.png'
import promoimgtwo from '../../assets/images/promo-img.png'
import promoimgthree from '../../assets/images/promo-img.png'
import partnerlogo from '../../assets/images/partner-logos.png'
import creditCcard from '../../assets/images/icons/credit-card.png'
import personalLoan from '../../assets/images/icons/personal-loan.png'
import ODLimit from '../../assets/images/icons/OD-limit.png'
import businessLoan from '../../assets/images/icons/Business-loan.png'
import homeLoan from '../../assets/images/icons/home-loan.png'
import propertLoan from '../../assets/images/icons/loan-against-property.png'
import carLoan from '../../assets/images/icons/car-loan.png'
import CAloan from '../../assets/images/icons/CA-loan.png'
import lifeInsurance from '../../assets/images/icons/life-insurance.png'
import healthInsurance from '../../assets/images/icons/health-insurance.png'
import carInsurance from '../../assets/images/icons/car-insurance.png'
import doctorLoan from '../../assets/images/icons/doctor-loan.png'
import axixBank from '../../assets/images/partners-logo/axis-bank.png'
import bajaj from '../../assets/images/partners-logo/bajaj-a.png'
import bajajFinserv from '../../assets/images/partners-logo/bajaj-finserv.png'
import canaraBank from '../../assets/images/partners-logo/canara-bank.png'
import careInsurance from '../../assets/images/partners-logo/care-insurance.png'
import citiBank from '../../assets/images/partners-logo/citi-bank.png'
import digit from '../../assets/images/partners-logo/digit.png'
import hdfcBank from '../../assets/images/partners-logo/hdfc-bank.png'
import hdfcLife from '../../assets/images/partners-logo/hdfc-life.png'
import hsbc from '../../assets/images/partners-logo/hsbc.png'
import iciciBank from '../../assets/images/partners-logo/icici-bank.png'
import idbiBank from '../../assets/images/partners-logo/idbi-bank.png'
import iffcoTokio from '../../assets/images/partners-logo/iffco-tokio.png'
import manipalCigna from '../../assets/images/partners-logo/manipal-cigna.png'
import maxBupa from '../../assets/images/partners-logo/max-bupa.png'
import nationalGeneral from '../../assets/images/partners-logo/national-general.png'
import pnb from '../../assets/images/partners-logo/pnb.png'
import royalInsurance from '../../assets/images/partners-logo/royal-insurance.png'
import sbiBank from '../../assets/images/partners-logo/sbi-bank.png'
import sbiGeneral from '../../assets/images/partners-logo/sbi-general.png'
import starInsurance from '../../assets/images/partners-logo/star-insurance.png'
import tataAig from '../../assets/images/partners-logo/tata-aig.png'
import unionBank from '../../assets/images/partners-logo/union-bank.png'
import yesBank from '../../assets/images/partners-logo/yes-bank.png'
import Slider from '../../component/Layout/Slider'
import { data } from '../../component/Common/data'
import ModalCom from '../../component/Common/Modal'

const Home = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div>
      {/* 3 small promo images */}
      <Slider />
      <section className='s_promo pb-5'>
        <Container fluid>
          <Row>
            <Col sm={4} md={4}>
              <Card>
                <img src={promoimg} alt='promotional' />
              </Card>
            </Col>
            <Col sm={4} md={4}>
              <Card>
                <img src={promoimgtwo} alt='promotional' />
              </Card>
            </Col>
            <Col sm={4} md={4}>
              <Card>
                <img src={promoimgthree} alt='promotional' />
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      {/* End 3 small promo images */}
      <section>
        <Container className='my-md-5'>
          <Row>
            <Col>
              <h2>Our Products</h2>
              {/* <p>We are a one-stop-shop for all your loan credit requirements. We aim to revolutionise how loans are distributed by infusing technology and digital platforms into the financial sector. With a network of over 50 lenders, Urban Money offers you the best financial products and services. We match your credit profile with the finance provider's criteria to process your loan application.</p> */}
            </Col>
          </Row>
          <Row className='services-card justify-content-center'>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={creditCcard} />
                </span>
                <h3>Credit Cards</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Card' interestIn='Credit Card'>
                  <span className='align-item-end'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={personalLoan} />
                </span>
                <h3>Persnal Loan</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Loan' interestIn='Personal Loan'>
                  <span className='align-item-end' href='/'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={ODLimit} />
                </span>
                <h3>Overdraft Limit</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Loan' interestIn='Overdraft Limit'>
                  <span className='align-item-end' href='/'>
                    Check Out
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={businessLoan} />
                </span>
                <h3>Business Loan</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Loan' interestIn='Business Loan'>
                  <span className='align-item-end' href='/'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={homeLoan} />
                </span>
                <h3>Home Loan</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Loan' interestIn='Home Loan'>
                  <span className='align-item-end' href='/'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={propertLoan} />
                </span>
                <h3>Loan Against Property</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Loan' interestIn='Loan Against Property'>
                  <span className='align-item-end' href='/'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={carLoan} />
                </span>
                <h3>Car Loan</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Loan' interestIn='Car Loan'>
                  <span className='align-item-end' href='/'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={doctorLoan} />
                </span>
                <h3>Doctor Loan</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Loan' interestIn='Doctor Loan'>
                  <span className='align-item-end' href='/'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={CAloan} />
                </span>
                <h3>CA Loan</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Loan' interestIn='CA Loan'>
                  <span className='align-item-end' href='/'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={lifeInsurance} />
                </span>
                <h3>Life Insurance</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Insurance' interestIn='Life Insurance'>
                  <span className='align-item-end'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={healthInsurance} />
                </span>
                <h3>Health Insurance</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Insurance' interestIn='Health Insurance'>
                  <span className='align-item-end'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col xs={6} sm={3} className='d-flex'>
              <Card>
                <span className='card__service-icon'>
                  <img src={carInsurance} />
                </span>
                <h3>Car Insurance</h3>
                <p>From 35+ options, choose a card matching your lifestyle & needs</p>
                <ModalCom serviceType='Insurance' interestIn='Car Insurance'>
                  <span className='align-item-end' href='/'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='my-5 s_investment-plan'>
        <Container>
          <Row className='mt-5'>
            <Col>
              <h2>Start Growing your wealth with the best investment plan</h2>
              {/* <p>At Urban Money, we aim to ease the burden of credit procedures through the help of financial calculators. You get access to various financial calculators, from EMI calculators to balance transfer calculators to FD calculators. With easy access to these calculators, you can now compute your monthly payments in advance and unlock better management and timely repayments.</p> */}
            </Col>
          </Row>
          <Row className='services-card'>
            <Col sm={6} className='d-flex'>
              <Card>
                <h3>Guaranteed Return Plan</h3>
                <p className='mt-3'>Get guaranteed retutrns & save upto Rs. 46,800 in tax underSec.80C Get guaranteed returns along with Life Cover</p>
                <ModalCom>
                  <span className='align-item-end pt-3'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
            <Col sm={6} className='d-flex'>
              <Card>
                <h3>Direct Mutual Funds</h3>
                <p className='mt-3'>Direct mutual funds have a lower cost ratio, hence, they provide investors higher returns in the long run. Buy direct mutual funds with ZERO commission.</p>
                <ModalCom serviceType='Investment' interestIn='Mutual Funds'>
                  <span className='align-item-end pt-3' href='/'>
                    Apply Now
                    <span className='ps-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z' />
                      </svg>
                    </span>
                  </span>
                </ModalCom>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      {/* End Invest plan section */}
      <section className='my-5 container'>
        <Row className='justify-content-center'>
          <Col sm={12} md={8}>
            <Row className='_rcb'>
              <Col xs={12} sm={6} md={7}>
                <p>
                  <strong>GET IN TOUCH</strong>
                </p>
                <h2>Ready to get started ?</h2>
                <p>Speak to our specialist</p>
              </Col>
              <Col xs={12} sm={6} md={5} className='d-flex justify-content-center align-self-center'>
                <ModalCom>
                  <Button>Request Call Back</Button>
                </ModalCom>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      {/* our partners */}
      <section className='my-5 s_our_partners'>
        <Container>
          <Row className='pt-5'>
            <Col>
              <h2>Our Partners</h2>
              <p>We work with these partners to create hussle free business with our customers.</p>
            </Col>
          </Row>
          <Row className='partner_logo'>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={iciciBank} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={hdfcBank} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={axixBank} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={pnb} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={sbiBank} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={bajajFinserv} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={citiBank} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={idbiBank} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={hsbc} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={yesBank} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={unionBank} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={iffcoTokio} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={careInsurance} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={hdfcLife} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={royalInsurance} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={sbiGeneral} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={tataAig} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={digit} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={manipalCigna} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={starInsurance} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={bajaj} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={maxBupa} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={nationalGeneral} />
            </Col>
            <Col xs={3} sm={2} className='d-flex my-3'>
              <img src={canaraBank} />
            </Col>
          </Row>
        </Container>
      </section>
      {/* End our partners */}

      {/* LOGIN SECTION */}
      <section className='my-5 login-page-bg'>
        <Container fluid>
          <Row className='justify-content-center'>
            <Col sm={4}>
              <Row className='justify-content-center text-left'>
                <Col>
                  <div className='login-page'>
                    <h1 className='text-center mb-5'>Login</h1>
                    <form>
                      <div className='mb-3'>
                        <label htmlFor='InputUserEmail' className='form-label'>
                          Email address
                        </label>
                        <input type='email' className='form-control' id='InputUserEmail' placeholder='Enter your mail ID' aria-describedby='emailHelp' aria-autocomplete='email' />
                        <div id='emailHelp' className='form-text'>
                          We'll never share your email with anyone else.
                        </div>
                        <div className='errormsg' aria-hidden='true' role='alert'></div>
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='UserPassword' className='form-label'>
                          Password
                        </label>
                        <input type='password' className='form-control' id='UserPassword' placeholder='Enter your password' />
                        <div className='errormsg' aria-hidden='true' role='alert'></div>
                      </div>
                      <div className='mb-4 form-check'>
                        <input type='checkbox' className='form-check-input' id='rememberpassword' />
                        <label className='form-check-label' htmlFor='rememberpassword'>
                          Remember Password
                        </label>
                      </div>
                      <button type='submit' className='btn btn-primary'>
                        Submit
                      </button>
                    </form>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* END LOGIN SECTION */}

      {/* CONTACT SECTION */}
      <section className='contact-page-bg'>
        <Container fluid>
          <Row className='justify-content-center'>
            <Col sm={5}>
              <Row className='justify-content-center text-left'>
                <Col>
                  <div className='login-page'>
                    <h1 className='text-center mb-5'>Contact Us</h1>
                    <div className='mt-3 fs-5'>
                      <h2>Loan pe Loan</h2>
                      <p>
                        <b>Address: </b>
                        {data.address}
                      </p>
                      <p>
                        <b>email: </b>
                        <span>
                          <a className=' text-decoration-none text-black' href={`mailto:${data.email}`}>
                            {data.email}
                          </a>
                        </span>
                      </p>
                      <p>
                        <b>Contact No.: </b>
                        <span>
                          <a className=' text-decoration-none text-black' href={`tel:${data.mobileNo}`}>
                            {data.mobileNo}
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      {/* END CONTACT SECTION */}
    </div>
  )
}
export default Home
