import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const Car = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Car Insurance</div>
          <div className='card-text fs-5'>Welcome to LoanpeLoan, your trusted financial partner. In this article, we will delve into the world of car insurance, an essential component of responsible car ownership. We understand the significance of protecting your vehicle and ensuring financial security in the event of unforeseen circumstances. Car insurance provides coverage against damages, theft, and liabilities, giving you peace of mind while on the road. Let's explore the importance of car insurance and the various aspects associated with it.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>Types of Car Insurance Policies:</div>
            <div className='fs-6'>There are different types of car insurance policies available in the market, each designed to cater to the specific needs of car owners. At LoanpeLoan, we offer the following types of car insurance policies:</div>
          </div>
          <div className='fs-6'>
            <ol className='list-group list-group-numbered '>
              <li className='list-group-item border-0'>
                <b>Third-Party Liability Car Insurance: </b>
                <span>This type of car insurance policy is mandatory by law in India. It covers the damages caused to the third party involved in the accident, including bodily injuries, death, and property damage. However, it does not cover the damages caused to your car or any injuries sustained by you.</span>
              </li>
              <li className='list-group-item border-0'>
                <b>Comprehensive Car Insurance: </b> <span>This is a more comprehensive type of car insurance policy that not only covers the damages caused to the third party but also covers the damages caused to your car and any injuries sustained by you. It also provides coverage against theft, fire, natural calamities, and other unforeseen events.</span>
              </li>
              <li className='list-group-item border-0'>
                <b>Zero Depreciation Car Insurance: </b> <span>This type of car insurance policy provides coverage without any depreciation on the value of the car's parts. It means that if any part of your car is damaged, the insurance company will pay the entire cost of repairing or replacing the part without considering its depreciation value.</span>
              </li>
              <li className='list-group-item border-0'>
                <b>Personal Accident Cover: </b> <span>This type of car insurance policy provides coverage against the medical expenses incurred due to any injury sustained by the driver or passengers during an accident.</span>
              </li>
            </ol>
          </div>
          <div className='my-4'>
            <div className='h3'>Benefits of Car Insurance:</div>
            <div className='fs-6'>Car insurance offers several benefits to car owners, such as:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Financial Protection: </b>
                  <span>Car insurance provides financial protection against the damages caused to your car and any third party involved in the accident. It ensures that you do not have to bear the financial burden of repairing or replacing your car or paying for the damages caused to the third party.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Legal Requirement: </b>
                  <span>: As per the Motor Vehicles Act, having car insurance is mandatory in India. Driving a car without insurance can lead to legal implications and hefty penalties.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Peace of Mind: </b>
                  <span>Car insurance offers peace of mind to car owners as it provides financial protection against any unforeseen event that may cause damage to the car or harm to the driver and passengers.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Additional Coverage: </b>
                  <span>Car insurance policies offer additional coverage options, such as roadside assistance, engine protection, and no-claim bonus, which enhance the coverage and benefits of the policy.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Insurance' interestIn='Car Insurance'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Car
