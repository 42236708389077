import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const SIP = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Systematic Investment Plan (SIP)</div>
          <div className='card-text fs-5'>Welcome to LoanPeLoan, your trusted financial partner. In this article, we will explore the Systematic Investment Plan (SIP), a popular investment strategy in India that offers individuals a disciplined and convenient approach to investing in mutual funds. We understand the importance of building wealth and achieving financial goals, and the SIP provides a structured method to accomplish these objectives. Let's dive into the world of the Systematic Investment Plan and discover how it can help you grow your investments.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>What is SIP?</div>
            <div className='fs-6'>SIP, or Systematic Investment Plan, is a popular investment vehicle that allows investors to invest a fixed amount of money regularly, usually on a monthly basis, in mutual funds. It is a disciplined investment approach that helps investors to invest regularly and systematically. SIP investments are typically made in equity, debt or hybrid mutual funds.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>How does SIP work?</div>
            <div className='fs-6'>
              In SIP, the investor sets a fixed amount of money to be invested at regular intervals, usually monthly. The money is then invested in a mutual fund scheme, which is chosen by the investor based on his/her financial goals, risk appetite and investment horizon. The mutual fund units are then allotted to the investor at the prevailing Net Asset Value (NAV) of the scheme.
              <br />
              The NAV is the value of each unit of the mutual fund scheme on a particular day. The investor is allotted a certain number of units based on the amount invested and the prevailing NAV. The investment amount is deducted from the investor's bank account automatically at the chosen interval, and the units are credited to the investor's demat account.
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Benefits of SIP:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Disciplined Investing: </b>
                  <span>SIP encourages disciplined investing, as it helps investors to invest regularly and systematically.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Rupee Cost Averaging: </b> <span>Rupee cost averaging is a benefit of SIP, where the investor buys more units when the NAV is low and fewer units when the NAV is high. This helps in reducing the overall cost of investment.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Power of Compounding: </b> <span>The power of compounding is one of the biggest benefits of investing in SIP. It is the process of earning interest on interest, which helps in growing the investment corpus exponentially over time.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Convenience: </b> <span>SIP is a convenient investment option, as it allows investors to invest regularly without having to worry about market fluctuations and timing the market.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Flexibility: </b> <span>SIP offers flexibility in terms of the investment amount and investment period. The investor can choose the investment amount and the investment period based on his/her financial goals and risk appetite.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Investment' interestIn='SIP Systematic Investment Plan'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SIP
