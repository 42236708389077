import React from 'react';
import ModalCom from '../../component/Common/Modal';
import { Button } from 'react-bootstrap';

const HomeLoan = () => {
  return (
    <div className="page-content p-5 container">
      <div className="card mt-4">
        <div className="card-body">
          <div className=" card-title h2">Home loan</div>
          <div className="card-text fs-5">At LoanpeLoan, we understand that buying a home is one of the biggest investments you'll ever make. That's why we offer a wide range of home loan products to help you find the right financing solution for your dream home. </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="my-4">
            <div className="h3">Find Your Dream Home with LoanpeLoan - Your One-Stop Destination for Home Loans</div>
            <div className="fs-6">Our platform simplifies the loan application process, making it easy for you to get the funding you need to purchase your dream home.</div>
          </div>
          <div className="my-4">
            <div className="h3">Understanding Home Loans</div>
            <div className="fs-6">A home loan, also known as a mortgage, is a type of loan used to purchase a home or property. Home loans come in various forms, including fixed-rate mortgages, adjustable-rate mortgages, and government-insured loans. The type of home loan that's right for you will depend on your financial situation, credit score, and other factors.</div>
          </div>
          <div className="my-4">
            <div className="h3">Why Choose LoanpeLoan for Your Home Loan?</div>
            <div className="fs-6">At LoanpeLoan, we understand that buying a home is an important financial decision. That's why we work with a network of trusted lenders to offer a wide range of home loan products. Our platform simplifies the loan application process, making it easy for you to find the right financing solution for your dream home. We offer competitive interest rates, flexible repayment terms, and fast funding, ensuring that your home loan process is stress-free.</div>
          </div>
          <div className="my-4">
            <div className="h3">Benefits of Home Loans</div>
            <div className="fs-6">
              Home loans offer several benefits that make them an attractive financing option for prospective homebuyers. Firstly, they provide access to the capital you need to purchase a home, even if you don't have the cash on hand. Additionally, home loans can offer tax benefits, allowing you to deduct the interest paid on your mortgage from your taxable income.
              <br />
              Another benefit of home loans is their long repayment terms, which can make your monthly payments more manageable. Additionally, home loans come with competitive interest rates, which can save you money over the life of your loan.
            </div>
          </div>
          <div className="my-4">
            <div className="h3">Eligibility and Application Process</div>
            <div className="fs-6">
              To qualify for a home loan, lenders typically consider factors such as your credit score, debt-to-income ratio, employment history, and income. At LoanpeLoan, we work with lenders who offer various home loan products, ensuring that we can match you with the right loan for your needs.
              <br />
              The loan application process on LoanpeLoan is quick and convenient. Our user-friendly platform guides you through the application process, prompting you to provide essential details about your financial situation and your home loan needs. Once you submit your application, our system matches you with potential lenders who can meet your requirements.
            </div>
          </div>
          <div className="my-4">
            <div className="h3">Applying for a Home Loan on LoanpeLoan</div>
            <div className="fs-6">Applying for a home loan on LoanpeLoan is easy and hassle-free. Our platform matches you with lenders who offer the home loan products you need, allowing you to compare loan options and choose the one that best suits your financial situation. Once you choose a lender, you'll receive funding quickly, allowing you to purchase your dream home with ease.</div>
          </div>
          <div className="my-3 d-flex justify-content-center">
            <ModalCom serviceType="Loan" interestIn="Home Loan">
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLoan;
