import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const CALoan = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>CA Loan</div>
          <div className='card-text fs-5'>As a Chartered Accountant (CA), you may have unique financial needs and requirements. From starting your own firm to upgrading your technology and equipment, there are a variety of reasons why you might need financing. That's where a CA loan comes in. At LoanpeLoan, we understand the challenges that CA professionals face when it comes to securing funding. Our CA loan is designed specifically to cater to the financial needs of CAs, offering a range of features and benefits to help you achieve your goals.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>What is a CA Loan?</div>
            <div className='fs-6'>A CA loan is a specialized loan product designed for Chartered Accountants. It can be used for a variety of purposes, such as starting a new practice, expanding an existing one, upgrading technology, buying equipment, or meeting working capital needs. CA loans are typically offered by banks, financial institutions, and NBFCs (Non-Banking Financial Companies).</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Features and Benefits of CA Loan:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>High Loan Amounts: </b>
                  <span>As a CA, you may have a high income, which can make you eligible for larger loan amounts. At LoanpeLoan, we offer CA loans of up to Rs. 50 lakhs, so you can meet your funding requirements without any hassle.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Flexible Repayment Tenure: </b> <span>Our CA loan comes with a flexible repayment tenure of up to 5 years. This means you can choose a repayment tenure that suits your financial situation and cash flow.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Quick Approval and Disbursal: </b> <span>We understand that as a CA, you may have urgent funding requirements. That's why our CA loan is designed to offer quick approval and disbursal. You can get your loan approved within 48 hours and the loan amount disbursed to your account within a few days.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Competitive Interest Rates: </b> <span>Our CA loan offers competitive interest rates, so you can get the funding you need without paying high interest charges.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>No Collateral Required: </b> <span>Our CA loan is an unsecured loan, which means you do not have to provide any collateral or security to get the loan. This makes the loan process faster and easier.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Minimal Documentation: </b> <span>At LoanpeLoan, we understand that time is money. That's why our CA loan requires minimal documentation, so you can get your loan approved quickly and without any hassle.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Eligibility Criteria for CA Loan:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>You must be a Chartered Accountant with a minimum of 3 years of practice.</li>
                <li className='list-group-item border-0'>You must have a minimum income of Rs. 5 lakhs per annum.</li>
                <li className='list-group-item border-0'>You must have a good credit score of 750 or above.</li>
                <li className='list-group-item border-0'>You must have a stable income and employment history.</li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Documents Required for CA Loan:</div>
            <div className='fs-6'>To apply for a CA Loan at LoanpeLoan, you need to submit the following documents:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>KYC Documents:</b> Aadhaar card, PAN card, passport, or driving license.
                </li>
                <li className='list-group-item border-0'>
                  <b>Proof of Income:</b> Salary slips, bank statements, or income tax returns.
                </li>
                <li className='list-group-item border-0'>
                  <b>Proof of Education:</b> Degree certificate or mark sheets.
                </li>
                <li className='list-group-item border-0'>
                  <b>Proof of Practice:</b> Registration certificate, practice certificate, or establishment certificate.{' '}
                </li>
                <li className='list-group-item border-0'>
                  <b>Other Documents:</b> Property papers (if applicable), business plan (if applicable)
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Loan' interestIn='CA Loan'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CALoan
