import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const MutualFund = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Mutual Fund</div>
          <div className='card-text fs-5'>Welcome to LoanPeLoan, your trusted financial resource. In this article, we will explore the world of mutual funds, a popular investment avenue that offers individuals the opportunity to grow their wealth and achieve their financial goals. We understand the importance of making informed investment decisions, and mutual funds provide a diverse range of options to suit different risk profiles and investment objectives. Let's dive into the world of mutual funds and discover how they can help you build a strong investment portfolio.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>What are Mutual Funds?</div>
            <div className='fs-6'>A mutual fund is a type of investment vehicle that pools money from multiple investors to purchase a diversified portfolio of stocks, bonds, or other securities. These securities are managed by a professional fund manager who makes investment decisions on behalf of the investors in the mutual fund. The return on investment is shared among the investors in proportion to their investment in the fund.</div>
          </div>

          <div className='h3'>Benefits of Mutual Funds:</div>
          <div className='fs-6'>
            <ol className='list-group list-group-numbered '>
              <li className='list-group-item border-0'>
                <b>Diversification: </b>
                <span>Mutual funds offer a diversified portfolio of investments, which helps to reduce the overall risk of the investment.</span>
              </li>
              <li className='list-group-item border-0'>
                <b>Professional Management: </b> <span>The fund manager has expertise in making investment decisions, which helps to achieve better returns on investment.</span>
              </li>
              <li className='list-group-item border-0'>
                <b>Liquidity: </b> <span>Mutual funds are highly liquid, which means investors can easily buy and sell their investments at any time.</span>
              </li>
              <li className='list-group-item border-0'>
                <b>Affordability: </b> <span>Mutual funds are affordable, and investors can start with a small amount of money.</span>
              </li>
              <li className='list-group-item border-0'>
                <b>Transparency: </b> <span> Mutual funds are required to provide regular updates to investors, which makes the investment process transparent.</span>
              </li>
            </ol>
          </div>

          <div className='my-4'>
            <div className='h3'>Risks of Mutual Funds:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Market Risk: </b>
                  <span>Mutual funds are subject to market risks, which means the value of the investment can go up or down based on market conditions.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Fees and Expenses: </b> <span>Mutual funds charge fees and expenses, which can reduce the overall return on investment.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Manager Risk: </b> <span>The fund manager's investment decisions can impact the overall return on investment, and if the fund manager performs poorly, it can affect the investors' returns.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Taxation: </b> <span>Mutual funds are subject to taxation, which can affect the overall return on investment.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Investment' interestIn='Mutual Funds'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MutualFund
