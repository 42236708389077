import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const CreditCard = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Credit Card</div>
          <div className='card-text fs-5'>Credit cards have become an indispensable financial tool for people worldwide. They offer a convenient way to make payments, whether it's for shopping, dining out, travel, or other expenses. Credit cards also offer several benefits, such as cashback rewards, discounts, and points-based systems, which can be redeemed for various products and services. At LoanpeLoan, we offer a variety of credit cards from top banks and financial institutions, providing customers with flexible payment options and features that cater to their specific needs.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>Benefits of Credit Cards:</div>
            <div className='fs-6'>Credit cards offer a host of benefits to consumers, making them a popular choice for making purchases. Here are some of the benefits of credit cards that customers can enjoy when they choose LoanpeLoan for their credit card needs:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Cashback Rewards: </b>
                  <span>Credit cards offer cashback rewards on eligible purchases, which can be redeemed for statement credits or used to pay down the card balance. This is an excellent way for customers to save money on their purchases.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Discounts: </b> <span>Credit cards offer discounts on purchases made with specific merchants or brands. This can help customers save money while enjoying their favorite products or services.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Points-Based Systems: </b> <span>Credit cards offer a points-based system, where customers earn points on eligible purchases that can be redeemed for a variety of rewards, such as travel miles, gift cards, merchandise, and more.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Flexible Payment Options: </b> <span>Credit cards provide customers with the flexibility to make payments over time, with the option to pay in full or over a set period. This allows customers to manage their finances and make payments that suit their budget.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Types of Credit Cards:</div>
            <div className='fs-6'>At LoanpeLoan, we offer a variety of credit cards to suit the needs of different customers. Here are some of the types of credit cards we offer:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>Rewards Credit Cards: </b>
                  <span>These cards offer cashback rewards or points-based systems that allow customers to earn rewards on eligible purchases.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Travel Credit Cards: </b> <span>These cards offer travel-related benefits such as travel miles, airport lounge access, and discounts on hotels and rental cars.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Balance Transfer Credit Cards: </b> <span>These cards allow customers to transfer their high-interest debt to a lower interest credit card, helping them save money on interest charges.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Secured Credit Cards: </b> <span>These cards require customers to make a security deposit to establish credit, making them a good option for customers with limited or poor credit history.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Business Credit Cards: </b> <span>These cards are designed for business owners, offering features such as employee cards, expense tracking, and cashback rewards on business purchases.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Card' interestIn='Credit Card'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditCard
