import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Contact from "../pages/ContactUs";
import About from "../pages/AboutUs";
import BusinessLoan from "../pages/Loans/Business";
import PersonalLoan from "../pages/Loans/Personal";
import HomeLoan from "../pages/Loans/Home";
import LoanAgainstProperty from "../pages/Loans/LoanAgainstProperty";
import EducationLoan from "../pages/Loans/Education";
import OverdraftLimit from "../pages/Loans/OverdraftLimit";
import DoctorLoan from "../pages/Loans/Doctor";
import CALoan from "../pages/Loans/CA";
import CSLoan from "../pages/Loans/CS";
import CarLoan from "../pages/Loans/Car";
import CreditCard from "../pages/Cards/Credit";
import DebitCard from "../pages/Cards/Debit";
import Prepaid from "../pages/Cards/Prepaid";
import Health from "../pages/Insurance/Health";
import Life from "../pages/Insurance/Life";
import Car from "../pages/Insurance/Car";
import FixedDeposit from "../pages/Investment/FixedDeposit";
import MutualFund from "../pages/Investment/MutualFund";
import RecurringDeposit from "../pages/Investment/RecurringDeposit";
import SIP from "../pages/Investment/SIP";
import EmployeeProvidentFund from "../pages/Investment/EmployeeProvidentFund";
import PublicProvidentFund from "../pages/Investment/PublicProvidentFund";
import Current from "../pages/Account/Current";
import Salary from "../pages/Account/Salary";
import Saving from "../pages/Account/Saving";
import Footer from "../component/Layout/Footer";
import Header from "../component/Layout/Header";
import ScrollToTop from "../component/Common/ScrollToTop";
import ModalCom from "../component/Common/Modal";
const routes = (
	<>
		<ScrollToTop>
			<Header />
			<main id="main">
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="/home" element={<Home />} />
					<Route path="/loan/business" element={<BusinessLoan />} />
					<Route path="/loan/personal" element={<PersonalLoan />} />
					<Route path="/loan/home" element={<HomeLoan />} />
					<Route path="/loan/Loan-Against-Property" element={<LoanAgainstProperty />} />
					<Route path="/loan/education" element={<EducationLoan />} />
					<Route path="/loan/overdraft" element={<OverdraftLimit />} />
					<Route path="/loan/doctor" element={<DoctorLoan />} />
					<Route path="/loan/CA" element={<CALoan />} />
					<Route path="/loan/CS" element={<CSLoan />} />
					<Route path="/loan/car" element={<CarLoan />} />
					<Route path="/card/credit" element={<CreditCard />} />
					<Route path="/card/debit" element={<DebitCard />} />
					<Route path="/card/prepaid" element={<Prepaid />} />
					<Route path="/insurance/health" element={<Health />} />
					<Route path="/insurance/life" element={<Life />} />
					<Route path="/insurance/car" element={<Car />} />
					<Route path="/investments/fixedDeposit" element={<FixedDeposit />} />
					<Route path="/investments/mutualfunds" element={<MutualFund />} />
					<Route path="/investments/recurringDeposit" element={<RecurringDeposit />} />
					<Route path="/investments/SIP" element={<SIP />} />
					<Route path="/investments/EPF" element={<EmployeeProvidentFund />} />
					<Route path="/investments/PPF" element={<PublicProvidentFund />} />
					<Route path="/account/current" element={<Current />} />
					<Route path="/account/salary" element={<Salary />} />
					<Route path="/account/saving" element={<Saving />} />
					{/* contact page */}
					<Route path="/contact-us" element={<Contact />} />
					{/* About page */}
					<Route path="/about-us" element={<About />} />
					{/* Login page */}
					<Route path="/login" element={<About />} />
				</Routes>
			</main>
			<div className="float-bot">
				<ModalCom>
					<button type="button" className="btn btn-float" data-bs-toggle="modal" data-bs-target="#confirmdata">
						<i className="fa fa-plus my-float"></i>
						Request a Callback
					</button>
				</ModalCom>
			</div>
			<Footer />
		</ScrollToTop>
	</>
);
export default routes;
