export const data = {
  ServiceType: [
    { value: 'Loan', label: 'Loan' },
    { value: 'Card', label: 'Card' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'Investment', label: 'Investment' },
    { value: 'Account', label: 'Account' },
  ],
  Interest: [
    { value: 'Personal Loan', label: 'Personal Loan', serviceType: 'Loan' },
    { value: 'Business Loan', label: 'Business Loan', serviceType: 'Loan' },
    { value: 'Home Loan', label: 'Home Loan', serviceType: 'Loan' },
    { value: 'Loan Against Property', label: 'Loan Against Property', serviceType: 'Loan' },
    { value: 'Education Loan', label: 'Education Loan', serviceType: 'Loan' },
    { value: 'Overdraft Limit', label: 'Overdraft Limit', serviceType: 'Loan' },
    { value: 'Doctor Loan', label: 'Doctor Loan', serviceType: 'Loan' },
    { value: 'CA Loan', label: 'CA Loan', serviceType: 'Loan' },
    { value: 'CS Loan', label: 'CS Loan', serviceType: 'Loan' },
    { value: 'Car Loan', label: 'Car Loan', serviceType: 'Loan' },
    { value: 'Credit Card', label: 'Credit Card', serviceType: 'Card' },
    { value: 'Debit Card', label: 'Debit Card', serviceType: 'Card' },
    { value: 'Prepaid', label: 'Prepaid', serviceType: 'Card' },
    { value: 'Health Insurance', label: 'Health Insurance', serviceType: 'Insurance' },
    { value: 'Life Insurance', label: 'Life Insurance', serviceType: 'Insurance' },
    { value: 'Car Insurance', label: 'Car Insurance', serviceType: 'Insurance' },
    { value: 'Fixed Deposit', label: 'Fixed Deposit', serviceType: 'Investment' },
    { value: 'Mutual Funds', label: 'Mutual Funds', serviceType: 'Investment' },
    { value: 'Recurring Deposit', label: 'Recurring Deposit', serviceType: 'Investment' },
    { value: 'SIP Systematic Investment Plan', label: 'SIP Systematic Investment Plan', serviceType: 'Investment' },
    { value: 'Employees Provident Fund', label: 'Employees Provident Fund', serviceType: 'Investment' },
    { value: 'Public Provident Fund', label: 'Public Provident Fund', serviceType: 'Investment' },
    { value: 'Current Account', label: 'Current Account', serviceType: 'Account' },
    { value: 'Salary Account', label: 'Salary Account', serviceType: 'Account' },
    { value: 'Saving Account', label: 'Saving Account', serviceType: 'Account' },
  ],
  address: 'S-45, Third Floor, Okhla Industrial Area Phase-II, New Delhi-110020',
  mobileNo: '+91 8899 388 388',
  email: 'info@loanpeloan.com',
};
