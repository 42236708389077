import React from 'react';
import ModalCom from '../../component/Common/Modal';
import { Button } from 'react-bootstrap';

const LoanAgainstProperty = () => {
  return (
    <div className="page-content p-5 container">
      <div className="card mt-4">
        <div className="card-body">
          <div className=" card-title h2">Loan Against Property</div>
          <div className="card-text fs-5">At LoanpeLoan, we are dedicated to helping you unlock the power of your property and secure the financing you need to meet your financial goals.</div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="my-4">
            <div className="h3">Unlock the Power of Your Property with Loan Against Property from LoanpeLoan</div>
            <div className="fs-6">At LoanpeLoan, we understand that your property is more than just a place to call home. It can also be a valuable asset that can help you secure the funds you need to meet your financial goals. Our loan against property product allows you to unlock the power of your property and leverage it to get the financing you need.</div>
          </div>
          <div className="my-4">
            <div className="h3">What is a Loan Against Property?</div>
            <div className="fs-6">A loan against property, also known as a mortgage loan, is a type of secured loan where you use your property as collateral to borrow money. The amount you can borrow depends on the value of your property, and you can use the loan for a variety of purposes, including debt consolidation, business expansion, and home renovation.</div>
          </div>
          <div className="my-4">
            <div className="h3">Why Choose LoanpeLoan for Your Loan Against Property?</div>
            <div className="fs-6">At LoanpeLoan, we offer a wide range of loan against property products from trusted lenders. Our platform simplifies the loan application process, making it easy for you to find the right financing solution for your needs. We offer competitive interest rates, flexible repayment terms, and fast funding, ensuring that your loan against property process is stress-free.</div>
          </div>
          <div className="my-4">
            <div className="h3">Benefits of Loan Against Property</div>
            <div className="fs-6">One of the main benefits of loan against property is that it provides access to a large amount of capital, which you can use for various purposes. Additionally, loan against property offers competitive interest rates, making it an affordable financing option. Another benefit of loan against property is that it offers flexible repayment terms, allowing you to choose a repayment schedule that suits your financial situation.</div>
          </div>
          <div className="my-4">
            <div className="h3">Eligibility and Application Process</div>
            <div className="fs-6">
              To qualify for a loan against property, lenders typically consider factors such as the value of your property, your credit score, and your income. At LoanpeLoan, we work with lenders who offer various loan against property products, ensuring that we can match you with the right loan for your needs.
              <br />
              The loan application process on LoanpeLoan is quick and convenient. Our user-friendly platform guides you through the application process, prompting you to provide essential details about your financial situation and your loan against property needs. Once you submit your application, our system matches you with potential lenders who can meet your requirements.
            </div>
          </div>
          <div className="my-4">
            <div className="h3">Applying for a Loan Against Property on LoanpeLoan</div>
            <div className="fs-6">Applying for a loan against property on LoanpeLoan is easy and hassle-free. Our platform matches you with lenders who offer the loan against property products you need, allowing you to compare loan options and choose the one that best suits your financial situation. Once you choose a lender, you'll receive funding quickly, allowing you to meet your financial goals with ease.</div>
          </div>
          <div className="my-3 d-flex justify-content-center">
            <ModalCom serviceType="Loan" interestIn="Loan Against Property">
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanAgainstProperty;
