import { Button, Col, Container, Row } from 'react-bootstrap'
import ModalCom from '../../component/Common/Modal'
import { data } from '../../component/Common/data'

const index = () => {
  return (
    <>
      <section className='about-page-bg'>
        <Container fluid>
          <Row className='justify-content-center'>
            <Col sm={10}>
              <Row className='justify-content-center text-left'>
                <Col>
                  <div className='login-page'>
                    <h1 className='text-center mb-5'>About Us</h1>
                    <div className='mt-3 fs-5'>
                      <p className='fs-3 mb-4'>Welcome to LoanPeloan, a leading finance company that provides financial solutions to individuals and businesses. We understand that managing finances can be daunting, and that's why we're here to help you make informed financial decisions that lead to financial stability and growth.</p>
                      <div className='h2'>Our Mission</div>
                      <p>Our mission is to empower individuals and businesses by providing them with the financial tools and resources they need to achieve their goals. We believe in fostering financial inclusion and ensuring that everyone has access to transparent and affordable financial services.</p>
                      <div className='h2'>Our Services</div>
                      <p>At LoanpeLoan, we offer a wide range of financial services designed to cater to diverse needs. Whether you're looking for personal loans, business loans, home loans, or insurance products, we have you covered. Our team of experts works closely with you to understand your requirements and recommend the best solutions that align with your financial goals.</p>
                      <div className='h2'>Transparency and Trust</div>
                      <p>We pride ourselves on our commitment to transparency and trust. We believe that open and honest communication is the foundation of any successful financial relationship. That's why we ensure that our customers have a clear understanding of our products, services, and associated terms and conditions. We are here to answer your questions, address your concerns, and guide you through the entire process.</p>
                      <div className='h2'>Expertise and Experience</div>
                      <p>With years of experience in the finance industry, our team brings a wealth of knowledge and expertise to the table. We keep ourselves updated with the latest market trends and regulations to provide you with accurate and relevant advice. Our dedicated professionals are passionate about helping you make informed financial decisions and guiding you towards a secure financial future.</p>
                      <div className='h2'>Customer-Centric Approach</div>
                      <p>At LoanpeLoan, our customers are at the heart of everything we do. We prioritize your needs and strive to provide exceptional customer service at every step. From the moment you reach out to us until your financial requirements are met, we ensure a smooth and seamless experience. We believe in building long-term relationships with our customers, based on trust, reliability, and mutual respect.</p>
                      <div className='h2'>Security and Confidentiality</div>
                      <p>We understand the importance of security and confidentiality when it comes to your financial information. Rest assured that we have robust security measures in place to safeguard your data and protect your privacy. Your information is treated with the utmost confidentiality, and we adhere to strict data protection regulations.</p>
                      <div className='h2'>Contact Us</div>
                      <p>We are here to assist you every step of the way. If you have any questions, require further information, or want to explore our financial services, please don't hesitate to contact us. Our dedicated customer support team is available to assist you via email, phone, or in-person consultations.</p>
                      <p>Thank you for choosing LoanpeLoan as your financial partner. We look forward to serving you and helping you achieve your financial goals.</p>
                      <div className='mt-5 d-flex justify-content-center'>
                        <ModalCom>
                          <Button>Contact Us</Button>
                        </ModalCom>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default index
