import React, { useState } from 'react'
import { Button, Carousel, Col, Modal } from 'react-bootstrap'
import banner from '../../assets/images/money-solution.jpg'
import bannerTwo from '../../assets/images/banner.png'

const Slider = () => {
  const [index, setIndex] = useState(0)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }
  return (
    <div>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <div className='row banner-holder'>
            <img alt={'Banner Image'} src={bannerTwo} width='100%' />
            <Col md={8} lg={6} sm={12}>
              <div className='banner-content'>
                <h2 className='pt-2'>We Help growing financing needs of individuals & enterprises.</h2>
                <p>Explore our suite of customized solution for your every financial need.</p>
                <Button className='btn btn-primary'>Explore Now</Button>
              </div>
            </Col>
            {/* <Col sm={6}>
            <Carousel.Caption>
                <p>Get your</p>
                <h2>
                  Latest Credit Score, <br />
                  Absolutely <span>FREE</span>
                </h2>
                <Button className="btn btn-primary">Get free cibil score</Button>
            </Carousel.Caption>
          </Col> */}
            <Col sm={6}>
              {/* <img
            className="d-block"
            src={bannerTwo}
            alt="Get Loan in easy way"
          /> */}
            </Col>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className='row banner-holder'>
            <img alt={'Banner Image'} src={bannerTwo} />
            <Col md={6} lg={6} sm={12}>
              <div className='banner-content'>
                <h2 className='pt-2'>
                  Get loan with us <br />
                  at minimum interest
                </h2>
                <p>Lorem ipsum is simply dummy text for demo. Lorem ipsum is simply dummy text for demo.</p>
                <Button className='btn btn-primary'>Know more</Button>
              </div>
            </Col>
          </div>
        </Carousel.Item>
      </Carousel>

      {/* floating */}

      {/* end floating */}

      {/* MODAL CHECK CIBIL */}

      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} className='right'>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Your Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-dialog-scrollable'>
          <form>
            <div className='mb-3'>
              <label htmlFor='ccs_fullName' className='form-label'>
                Full Name (As per PAN Card)
              </label>
              <input type='text' className='form-control' id='ccs_fullName' placeholder='Enter your full name' aria-autocomplete='name' />
              <div className='errormsg' aria-hidden='true' role='alert'></div>
            </div>
            <div className='mb-3'>
              <label htmlFor='ccs_contactNumber' className='form-label'>
                Mobile Number
              </label>
              <input type='number' className='form-control' id='ccs_contactNumber' placeholder='Enter your 10 digit Mobile number' aria-autocomplete='phone-no' />
              <div className='errormsg' aria-hidden='true' role='alert'></div>
            </div>
            <div className='mb-3'>
              <label htmlFor='ccs_email' className='form-label'>
                Email ID
              </label>
              <input type='text' className='form-control' id='ccs_email' placeholder='Enter your Email ID (Optional)' aria-autocomplete='email' />
              <div className='errormsg' aria-hidden='true' role='alert'></div>
            </div>
            <div className='mb-3'>
              <label htmlFor='ccs_msg' className='form-label'>
                Comment
              </label>
              <input type='text' className='form-control' id='ccs_msg' placeholder='Type here your message' />
              <div className='errormsg' aria-hidden='true' role='alert'></div>
            </div>
            <div className='mb-4'>
              <button className='btn btn-primary rcb-btn' data-bs-target='#confirmdata' data-bs-toggle='modal' data-bs-dismiss='modal'>
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary">Action</Button>
        </Modal.Footer> */}
      </Modal>

      {/* END MODAL CHECK CIBIL */}
    </div>
  )
}

export default Slider
