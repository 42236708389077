import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const OverdraftLimit = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Overdraft Limit</div>
          <div className='card-text fs-5'>At LoanpeLoan, we offer a range of overdraft limit products from trusted lenders to help businesses maintain their financial stability.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>Manage Your Cash Flow with Overdraft Limits from LoanpeLoan</div>
            <div className='fs-6'>Maintaining a stable cash flow is essential for the smooth operation of any business. However, sometimes businesses may face cash flow shortages due to unexpected expenses, late payments from customers, or other factors. In such situations, overdraft facilities can help businesses manage their cash flow needs. At LoanpeLoan, we offer a range of overdraft limit products from trusted lenders to help businesses maintain their financial stability.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>What is an Overdraft Limit?</div>
            <div className='fs-6'>An overdraft limit is a credit facility offered by banks to their customers, allowing them to withdraw more money than their account balance up to a pre-approved limit. Overdraft limits provide a flexible and convenient financing option for businesses that require short-term cash flow solutions.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Why Choose LoanpeLoan for Your Overdraft Limit?</div>
            <div className='fs-6'>At LoanpeLoan, we understand the unique financial needs of businesses and offer a range of overdraft limit products from trusted lenders. Our platform simplifies the application process, making it easy for you to find the right overdraft limit product for your needs.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Benefits of Overdraft Limits</div>
            <div className='fs-6'>Overdraft limits provide businesses with a flexible financing option, allowing them to manage their cash flow needs conveniently. Some of the key benefits of overdraft limits include:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b> Flexibility: </b>
                  <span>Overdraft limits offer businesses the flexibility to manage their cash flow needs as per their requirements.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Convenience: </b> <span>Overdraft limits are a convenient financing option for businesses, as they can be accessed quickly and easily.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Cost-Effective: </b> <span> Overdraft limits typically have lower interest rates than other short-term financing options, making them a cost-effective solution for businesses.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Repayment Flexibility: </b> <span> Overdraft limits offer businesses the flexibility to repay the borrowed amount as per their financial situation.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Eligibility and Application Process</div>
            <div className='fs-6'>
              To be eligible for an overdraft limit, businesses typically need to have a good credit score and a stable financial track record. Additionally, businesses may need to provide financial statements, cash flow projections, and other relevant documents as proof of their financial position.
              <br />
              The application process on LoanpeLoan is user-friendly and straightforward. Our platform guides you through the application process, prompting you to provide essential details about your financial situation and overdraft limit requirements. Once you submit your application, our system matches you with potential lenders who can meet your requirements.
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Applying for an Overdraft Limit on LoanpeLoan</div>
            <div className='fs-6'>Applying for an overdraft limit on LoanpeLoan is easy and hassle-free. Our platform matches you with lenders who offer the overdraft limit products you need, allowing you to compare loan options and choose the one that best suits your financial situation. Once you choose a lender, you'll receive funding quickly, allowing you to manage your cash flow needs with confidence.</div>
          </div>
          <div className='my-4'>
            <div className='h3'>Types of Overdraft Limits</div>
            <div className='fs-6'>LoanpeLoan offers various types of overdraft limits to meet different needs, including:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b> Secured Overdraft Limit: </b>
                  <span>This type of overdraft limit is secured against collateral such as property, stocks, or other assets.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Unsecured Overdraft Limit: </b> <span>This type of overdraft limit does not require collateral, but may have higher interest rates and stricter eligibility criteria.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Cash Credit Limit:</b> <span> This type of overdraft limit is designed for businesses that need regular access to short-term financing</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Overdraft Limit Amount and Repayment</div>
            <div className='fs-6'>The overdraft limit amount varies depending on the borrower's financial position and creditworthiness. Lenders typically offer overdraft limits ranging from Rs. 1 lakh to Rs. 50 lakh or more. Repayment terms are also flexible, with most lenders offering a repayment tenure of up to 12 months.</div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Loan' interestIn='Overdraft Limit'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverdraftLimit
