import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const Prepaid = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Prepaid cards</div>
          <div className='card-text fs-5'>Welcome to LoanpeLoan, where we help you understand everything you need to know about financial products and services. In this article, we will be discussing prepaid cards, which are a type of payment card that allows you to load money onto them and use them for transactions.</div>
          <div className='card-text fs-5'>Prepaid cards are becoming increasingly popular due to the convenience and flexibility they offer. They can be used to make purchases online, in-store, and even overseas. Here's everything you need to know about prepaid cards</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>What is a Prepaid Card?</div>
            <div className='fs-6'>A prepaid card is a payment card that allows you to load money onto it in advance. You can then use the card to make purchases, withdraw cash, or pay bills. Unlike credit cards, prepaid cards do not require a credit check or bank account to apply for them. They are available to anyone regardless of their credit history.</div>
          </div>

          <div className='my-4'>
            <div className='h3'>Types of Prepaid Cards:</div>
            <div className='fs-6'>There are three types of prepaid cards:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered'>
                <li className='list-group-item border-0'>
                  <b>Open-Loop Prepaid Cards:</b>
                  <span>Open-loop prepaid cards are also known as network-branded prepaid cards. These cards are issued by financial institutions and are affiliated with major card networks such as Visa, Mastercard, and American Express. They can be used anywhere the card network is accepted.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Closed-Loop Prepaid Cards:</b> <span>Closed-loop prepaid cards are also known as merchant gift cards. These cards are issued by retailers and can only be used at their stores or websites.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Semi-Closed Loop Prepaid Cards:</b> <span>Semi-closed loop prepaid cards are issued by companies that are not financial institutions or retailers. These cards can be used at a limited number of merchants that have agreements with the issuer.</span>
                </li>
              </ol>
            </div>
          </div>

          <div className='my-4'>
            <div className='h3'>How do Prepaid Cards Work?</div>
            <div className='fs-6'>To use a prepaid card, you first need to purchase it and load it with funds. You can load funds onto the card by:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>Depositing cash at a participating retail location</li>
                <li className='list-group-item border-0'>Transferring funds from your bank account</li>
                <li className='list-group-item border-0'>Direct deposit of your paycheck or benefits</li>
                <li className='list-group-item border-0'>Reloadable options through mobile apps and online portals.</li>
                <li className='list-group-item border-0'>Once the card is loaded, you can use it to make purchases or withdraw cash at ATMs. You can also use prepaid cards to pay bills and make online purchases.</li>
              </ol>
            </div>
          </div>

          <div className='my-4'>
            <div className='h3'>Benefits of Prepaid Cards:</div>
            <div className='fs-6'>
              There are three types of prepaid cards:Prepaid cards have several advantages, including:
              <ol className='list-group list-group-numbered'>
                <li className='list-group-item border-0'>
                  <b>Convenience:</b>
                  <span>Prepaid cards are widely accepted, and they can be used to make purchases online and in-store. You can also use them to withdraw cash at ATMs.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Flexibility:</b> <span>Prepaid cards are available to anyone, regardless of their credit history. They can be loaded with any amount of money, and you can choose when and how much to load.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Control:</b> <span>Prepaid cards allow you to control your spending, as you can only spend what you have loaded onto the card. This can be useful for people who are trying to stick to a budget or who want to limit their spending.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Security:</b> <span>Prepaid cards are often more secure than carrying cash, as they can be replaced if lost or stolen. Some prepaid cards also offer fraud protection, which can help you recover your funds if your card is used fraudulently.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Card' interestIn='Prepaid'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Prepaid
