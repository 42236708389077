import React, { useState } from 'react'
import { Container, Button, Navbar, NavDropdown } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import megamenu from '../../assets/images/mega-menu.png'
import logo from '../../assets/images/LPL_White.png'
import { Link } from 'react-router-dom'
import ModalCom from '../Common/Modal'

const Header = () => {
  const [show, setShow] = useState(false)

  const handleShow = () => setShow(true)
  return (
    <header>
      <div className='fixed-top'>
        <Container fluid className='top_bar_cibilScore'>
          <div className=''>
            <p className='justify-content-center text-center'>
              Credit Score & report{' '}
              <s>
                <span>worth ₹ 1200</span>
              </s>{' '}
              <strong>Absolutely FREE</strong>
              <Button className='btn btn-primary mx-3' onClick={handleShow}>
                Get free cibil score
              </Button>
            </p>
          </div>
        </Container>

        <Navbar expand='lg'>
          <Container fuild='true'>
            <Navbar.Brand as={Link} to={'/'}>
              <img src={logo} alt='Loan pe Loan logo' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='ms-auto me-5'>
                <Nav.Link as={Link} to='/about-us'>
                  About us
                </Nav.Link>
                <NavDropdown title='Products' id='basic-nav-dropdown' className='dropdown-mega position-static'>
                  <div className='mega-content px-4'>
                    <div className='container-fluid'>
                      <div className='row'>
                        <div className='col-12 col-sm-4 col-md-3 py-4'>
                          <h5 className='ms-3'>LOANs</h5>
                          {/* <NavDropdown.Divider /> */}
                          <NavDropdown.Item as={Link} to={'/loan/personal'}>
                            Personal Loan
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/loan/business'}>
                            Business Loan
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/loan/home'}>
                            Home Loan
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/loan/Loan-Against-Property'}>
                            Loan Against Property
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/loan/education'}>
                            Education Loan
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/loan/overdraft'}>
                            Overdraft Limit
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/loan/doctor'}>
                            Doctor Loan
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/loan/CA'}>
                            CA Loan
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/loan/CS'}>
                            CS Loan
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/loan/car'}>
                            Car Loan
                          </NavDropdown.Item>
                        </div>
                        <div className='col-12 col-sm-4 col-md-3 py-4'>
                          <h5 className='ms-3'>CARDs</h5>
                          {/* <NavDropdown.Divider /> */}
                          <NavDropdown.Item as={Link} to={'/card/credit'}>
                            Credit Card
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/card/debit'}>
                            Debit Card
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/card/prepaid'}>
                            Prepaid
                          </NavDropdown.Item>

                          <div className='py-4'>
                            <h5 className='ms-3'>INSURANCE</h5>
                            {/* <NavDropdown.Divider /> */}
                            <NavDropdown.Item as={Link} to={'/insurance/health'}>
                              Health Insurance
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={'/insurance/life'}>
                              Life Insurance
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={'/insurance/car'}>
                              Car Insurance
                            </NavDropdown.Item>
                          </div>
                        </div>
                        <div className='col-12 col-sm-4 col-md-3 py-4'>
                          <h5 className='ms-3'>Investments</h5>
                          {/* <NavDropdown.Divider /> */}
                          <NavDropdown.Item as={Link} to={'/investments/fixedDeposit'}>
                            Fixed Deposit
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/investments/mutualfunds'}>
                            Mutual Funds
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/investments/recurringDeposit'}>
                            Recurring Deposit
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/investments/SIP'}>
                            SIP Systematic Investment Plan
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/investments/EPF'}>
                            Employees Provident Fund
                          </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={'/investments/PPF'}>
                            Public Provident Fund
                          </NavDropdown.Item>

                          <div className='py-4'>
                            <h5 className='ms-3'>ACCOUNTs</h5>
                            {/* <NavDropdown.Divider /> */}
                            <NavDropdown.Item as={Link} to={'/account/current'}>
                              Current Account
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={'/account/salary'}>
                              Salary Account
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to={'/account/saving'}>
                              Saving Account
                            </NavDropdown.Item>
                          </div>
                        </div>
                        <div className='col-12 col-sm-4 col-md-3 pt-4'>
                          <img src={megamenu} alt='growth' />
                        </div>
                      </div>
                    </div>
                  </div>
                </NavDropdown>
                <Nav.Link as={Link} to='/contact-us'>
                  Contact us
                </Nav.Link>
                <Nav.Link as={Link} to='/about-us'>
                  Login
                </Nav.Link>
              </Nav>
              <Nav>
                <ModalCom>
                  <Nav className='btn-signin'>GET LAON</Nav>
                </ModalCom>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {/* old banner */}
      {/* <div className="justify-content-center">
        <div className="banner-holder">
        <img alt={"Banner Image"} src={banner} width="100%"/>
          <Col md={6} lg={6} sm={12}>
            
              
              <div className="banner-content">
                <h2 className="pt-2">
                  Get loan with us <br />
                  at minimum interest
                </h2>
                <p>
                  Lorem ipsum is simply dummy text for demo. Lorem ipsum is simply dummy text for demo.
                </p>
                <Button className="btn btn-primary">Know more</Button>
              </div>
            
          </Col>
        </div>
      </div> */}
      {/* END */}
    </header>
  )
}
export default Header
