import React from 'react';
import ModalCom from '../../component/Common/Modal';
import { Button } from 'react-bootstrap';

const PersonalLoan = () => {
  return (
    <div className="page-content p-5 container">
      <div className="card mt-4">
        <div className="card-body">
          <div className=" card-title h2">Personal Loan</div>
          <div className="card-text fs-5">Welcome to LoanpeLoan, your one-stop destination for all things personal loans! We understand that unexpected expenses can arise at any time, and having access to extra funds can be crucial.</div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="my-4">
            <div className="h3">Personal Loans - Your Guide to Financial Flexibility</div>
            <div className="fs-6">At LoanpeLoan, we understand that life can be unpredictable, and unexpected expenses can arise at any time. Whether you need funds to cover medical bills, consolidate debt, or make a big purchase, personal loans can provide the financial flexibility you need. In this guide, we'll cover everything you need to know about personal loans, including how they work, the benefits, eligibility requirements, and how to apply on our platform.</div>
          </div>
          <div className="my-4">
            <div className="h3">How Do Personal Loans Work?</div>
            <div className="fs-6">A personal loan is an unsecured loan that is based on creditworthiness and ability to repay, rather than requiring collateral like a car or a house. Personal loans typically come with fixed interest rates and repayment terms, making it easy to budget and plan for the loan payments. The loan amount you can qualify for will depend on factors such as your credit score, income, and debt-to-income ratio.</div>
          </div>
          <div className="my-4">
            <div className="h3">Benefits of Personal Loans</div>
            <div className="fs-6">
              One of the main benefits of personal loans is their flexibility. Unlike some other loans, personal loans can be used for a wide range of purposes, from home improvements to wedding expenses. Additionally, personal loans can be a great option for individuals with high-interest credit card debt, as they often come with lower interest rates.
              <br />
              Another advantage of personal loans is their fixed repayment terms. Unlike credit cards or lines of credit, personal loans come with a set repayment schedule, which can make it easier to budget and plan for your payments. Additionally, personal loans can be a good way to build credit, as long as you make your payments on time and in full.
            </div>
          </div>
          <div className="my-4">
            <div className="h3">Eligibility Requirements for Personal Loans</div>
            <div className="fs-6">To qualify for a personal loan, you typically need to have a good credit score and a stable income. Some lenders may also require a certain debt-to-income ratio or a minimum credit history. However, LoanpeLoan makes the application process quick and easy, and our platform allows you to compare loan options from multiple lenders. To apply for a personal loan on LoanpeLoan, you'll need to provide some basic information, such as your name, address, and employment details. You'll also need to authorize a credit check, which will help lenders determine your creditworthiness and loan eligibility.</div>
          </div>
          <div className="my-4">
            <div className="h3">Applying for a Personal Loan on LoanpeLoan</div>
            <div className="fs-6">Applying for a personal loan on LoanpeLoan is simple and convenient. Our platform offers a variety of loan options from trusted lenders, making it easy to find the right loan for your needs. Plus, our application process is quick and easy - simply fill out our online form and get matched with loan options in minutes. Once you've been matched with loan options, you can compare interest rates, loan terms, and fees to find the right loan for your needs. You'll also have the opportunity to ask lenders any questions you may have before making a final decision.</div>
          </div>
          <div className="mt-5">
            <div className="my-3 d-flex justify-content-center">
              <ModalCom serviceType="Loan" interestIn="Personal Loan">
                <Button>Apply</Button>
              </ModalCom>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalLoan;
