import React from 'react';
import ModalCom from '../../component/Common/Modal';
import { Button } from 'react-bootstrap';

const BusinessLoan = () => {
  return (
    <div className="page-content p-5 container">
      <div className="card mt-4">
        <div className="card-body">
          <div className=" card-title h2">Business loan</div>
          <div className="card-text fs-5">At LoanpeLoan, we are committed to helping businesses of all sizes secure the financing they need to grow and thrive. </div>
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <div className="my-4">
            <div className="h3">Fuel Your Business Growth with LoanpeLoan - The Ultimate Destination for Business Loans</div>
            <div className="fs-6">Welcome to LoanpeLoan, your ultimate destination for business loans! As a business owner, you understand the importance of having access to capital to fuel your growth and expansion. That's where we come in - we are dedicated to helping entrepreneurs like you secure the financing you need to take your business to the next level.</div>
          </div>
          <div className="my-4">
            <div className="h3">Understanding Business Loans</div>
            <div className="fs-6">Business loans are a popular financing option for business owners. They provide the necessary capital to fund various business needs, including expansion, inventory purchases, hiring employees, and more. Business loans come in different forms, including term loans, lines of credit, and equipment financing, each designed to meet specific business needs.</div>
          </div>
          <div className="my-4">
            <div className="h3">Why Choose LoanpeLoan for Your Business Loan?</div>
            <div className="fs-6">At LoanpeLoan, we understand that every business is unique, and that's why we offer a wide range of loan products from trusted lenders. Our platform simplifies the loan application process, making it easy for you to find the right financing solution for your business. We offer competitive interest rates, flexible repayment terms, and quick funding, ensuring that your business has the capital it needs to thrive.</div>
          </div>
          <div className="my-4">
            <div className="h3">Benefits of Business Loans</div>
            <div className="fs-6">Business loans offer several benefits that can help grow your business. They provide access to capital, which can be used to expand your business, hire employees, or purchase inventory. Additionally, business loans can help you build business credit when managed responsibly, improving your future borrowing potential. Another advantage of business loans is their flexibility. Depending on the loan type, you may have the option to choose repayment periods that align with your cash flow, ensuring manageable monthly payments. Additionally, business loans often come with competitive interest rates, allowing you to minimize borrowing costs and maximize profitability.</div>
          </div>
          <div className="my-4">
            <div className="h3">Eligibility and Application Process</div>
            <div className="fs-6">To qualify for a business loan, lenders typically consider factors such as your business's financial health, creditworthiness, and industry stability. At LoanpeLoan, we work with lenders who offer both traditional and alternative lending options, increasing your chances of securing the financing you need. The loan application process on LoanpeLoan is quick and convenient. Our user-friendly platform guides you through the application process, prompting you to provide essential details about your business and its financing needs. Once you submit your application, our system matches you with potential lenders who can meet your requirements.</div>
          </div>
          <div className="my-4">
            <div className="h3">Applying for a Business Loan on LoanpeLoan</div>
            <div className="fs-6">Applying for a business loan on LoanpeLoan is easy and hassle-free. Our platform matches you with lenders who offer the financing you need, allowing you to compare loan options and choose the one that best suits your business needs. Once you choose a lender, you'll receive funding quickly, allowing you to focus on growing your business.</div>
          </div>
          <div className="mt-5">
            <div className="my-3 d-flex justify-content-center">
              <ModalCom serviceType="Loan" interestIn="Business Loan">
                <Button>Apply</Button>
              </ModalCom>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessLoan;
