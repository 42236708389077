import React from 'react'
import ModalCom from '../../component/Common/Modal'
import { Button } from 'react-bootstrap'

const CarLoan = () => {
  return (
    <div className='page-content p-5 container'>
      <div className='card mt-4'>
        <div className='card-body'>
          <div className=' card-title h2'>Car Loan</div>
          <div className='card-text fs-5'>LoanPeLoan understands that owning a car is a dream for many individuals and families in India. However, buying a car can be a significant financial burden for most people. That's where we come in. We offer car loans that can help you purchase the car of your dreams without having to worry about the financial burden. Whether you're buying a new car or a used car, LoanPeLoan has the perfect car loan for you.</div>
        </div>
      </div>
      <div className='card mt-3'>
        <div className='card-body'>
          <div className='my-4'>
            <div className='h3'>Features of Car Loans:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>LoanPeLoan offers car loans with loan amounts up to 100% of the ex-showroom price of the car.</li>
                <li className='list-group-item border-0'> Our car loans come with flexible repayment options ranging from 12 to 84 months.</li>
                <li className='list-group-item border-0'>We offer competitive interest rates on our car loans, ensuring that you get the best deal possible.</li>
                <li className='list-group-item border-0'>Our loan application process is quick and hassle-free, allowing you to get the funds you need in no time.</li>
                <li className='list-group-item border-0'>We offer doorstep service, so you don't have to worry about visiting our offices to apply for a loan.</li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Types of Car Loans:</div>
            <div className='fs-6'>TLoanPeLoan offers two types of car loans: new car loans and used car loans.</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>
                  <b>New Car Loans: </b>
                  <span>If you're looking to buy a new car, LoanPeLoan can help you finance your dream car. We offer new car loans with loan amounts up to 100% of the ex-showroom price of the car. Our new car loans come with flexible repayment options ranging from 12 to 84 months, ensuring that you can choose a repayment plan that suits your financial situation. We also offer competitive interest rates on our new car loans, so you don't have to worry about paying a high interest rate.</span>
                </li>
                <li className='list-group-item border-0'>
                  <b>Used Car Loans: </b>
                  <span>If you're looking to buy a used car, LoanPeLoan can help you finance your purchase. We offer used car loans with loan amounts up to 100% of the market value of the car. Our used car loans come with flexible repayment options ranging from 12 to 60 months, ensuring that you can choose a repayment plan that suits your financial situation. We also offer competitive interest rates on our used car loans, so you don't have to worry about paying a high interest rate.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Eligibility Criteria:</div>
            <div className='fs-6'>To be eligible for a car loan from LoanPeLoan, you must meet the following criteria:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>You must be at least 21 years old and not more than 65 years old. </li>
                <li className='list-group-item border-0'>You must have a minimum income of Rs. 20,000 per month. </li>
                <li className='list-group-item border-0'>You must have a good credit score and credit history. </li>
                <li className='list-group-item border-0'>You must have a stable source of income.</li>
              </ol>
            </div>
          </div>
          <div className='my-4'>
            <div className='h3'>Documents Required:</div>
            <div className='fs-6'>To apply for a car loan from LoanPeLoan, you will need to submit the following documents:</div>
            <div className='fs-6'>
              <ol className='list-group list-group-numbered '>
                <li className='list-group-item border-0'>Proof of identity (PAN card, passport, or driving license) </li>
                <li className='list-group-item border-0'>Proof of address (Aadhaar card, voter ID, or utility bill) </li>
                <li className='list-group-item border-0'>Proof of income (Income tax returns, bank statements, or salary slips) </li>
                <li className='list-group-item border-0'>Proof of employment (Appointment letter, employee ID card, or employment contract)</li>
                <li className='list-group-item border-0'>Quotation from the car dealer </li>
              </ol>
            </div>
          </div>
          <div className='my-3 d-flex justify-content-center'>
            <ModalCom serviceType='Loan' interestIn='Car Loan'>
              <Button>Apply</Button>
            </ModalCom>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CarLoan
